import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '0',
    paddingBottom: '0',
  },
}));

const ButtonBox = ({ isEdit, openPreview, tempSave, onSave, onUpdate }) => {
  const classes = useStyles();

  return (
    <Card style={{overflow: 'visible'}}>
      <CardContent>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
          className={classes.root}
        >
          <Grid container justify="flex-end" spacing={1}>
            {
              !isEdit ? (
                <React.Fragment>
                  <Grid item sm='auto' xs={12}>
                    <Button
                      variant="contained"
                      onClick={tempSave}
                      style={{marginRight: '20px'}}
                      fullWidth
                    >
                      Temporary Save
                    </Button>
                  </Grid>
                  <Grid item sm='auto' xs={12}>
                    <Button
                      variant="contained"
                      onClick={openPreview}
                      style={{marginRight: '20px'}}
                      fullWidth
                    >
                      Preview
                    </Button>
                  </Grid>
                  <Grid item sm='auto' xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onSave('tg')}
                      fullWidth
                    >
                      Arrange
                    </Button>
                  </Grid>
                  <Grid item sm='auto' xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onSave('tge')}
                      fullWidth
                    >
                      RQ for Quotation
                    </Button>
                  </Grid>
                </React.Fragment>
              ) : (
                  <Grid item sm='auto' xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onUpdate}
                      fullWidth
                    >
                      RQ for Update
                    </Button>
                  </Grid>
              )
            }
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ButtonBox;