import React from 'react';

import BasicInform from './';

const RoomTypeWrapper = ({ index, field }) => {

  const onAddRoomType = (event) => {
    if (event.target.value === "") return ;

    const found = field.roomList.find( (room) => (room.code === event.target.value))
    if (found === undefined) {
      let temp = [...field.roomList, {
          cnt: 0,
          code: event.target.value, 
          pax: event.target[event.target.selectedIndex].getAttribute('pax'),
          text: event.target[event.target.selectedIndex].text,
        }].sort((a,b) => {
          if (a.text > b.text) return 1;
          if (b.text > a.text) return -1;
          return 0;
        });

      field.setRoomList(temp);
    }
  };

  field.children[0] = {
    ...field.children[0],
    m: onAddRoomType
  };

  return (
    <React.Fragment>
      <BasicInform  index={1000+index} field={field.children[0]} />
      <BasicInform  index={1001+index} field={field.children[1]} />
    </React.Fragment>
  );
}

export default RoomTypeWrapper;