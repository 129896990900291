import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Hidden,
  List,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavItem from './NavItem';

import GroupIcon from '@material-ui/icons/Group';
import HotelOutlinedIcon from '@material-ui/icons/HotelOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ImageIcon from '@material-ui/icons/Image';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PinDropIcon from '@material-ui/icons/PinDrop';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import InputIcon from '@material-ui/icons/Input';

import { SimpleToastWrapper } from 'src/components/Common';
import * as AccountActions from 'src/reducers/Account/actions';

import * as CommonServices from 'src/services/CommonServices';

import { isNull, isNullStr } from "src/components/Common/Constants";
import { cloneDeep } from 'lodash';

const items = [
  {
    href: '/group/list',
    icon: GroupIcon,
    title: 'Group list'
  },
  {
    href: '/group/edit',
    icon: GroupAddOutlinedIcon,
    title: 'Create group'
  },
  {
    href: '/group/hotels',
    icon: HotelOutlinedIcon,
    title: 'Hotel option list'
  },
  {
    href: '/supplier/attractions',
    icon: PinDropIcon,
    title: '[Supplier] Attraction'
  },
  {
    href: '/supplier/tv',
    icon: PinDropIcon,
    title: '[Supplier] Technical visit'
  },
  {
    href: '/supplier/theme',
    icon: PinDropIcon,
    title: '[Supplier] Theme'
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ AccountActions, account, onMobileClose, openMobile, onLogoutHandler }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [openInform, setOpenInform] = useState(false);
  const [openLogo, setOpenLogo] = useState(false);

  const [cate1, setCate1] = useState([]);
  const [interestItems, setInterestItems] = useState([]);

  const [errAddress, setErrAddress] = useState(false);
  const [errName, setErrName] = useState(false);
  const [errPhone, setErrPhone] = useState(false);
  const [errEmail, setErrEmail] = useState(false);

  const fetchCate1 = async () => {
    const datas = await CommonServices.getCodes({ groupId: 53 });
    setCate1(datas.data.cdMstTb.cdDtlTbs);
  };
  const handleClickOpen = () => { setOpen(true); }
  const handleClose = () => { setOpen(false); }
  const handleClickOpenInform = () => { setOpenInform(true); }
  const handleCloseInform = () => { setOpenInform(false); }
  const handleClickOpenLogo = () => { setOpenLogo(true); }
  const handleCloseLogo = () => { setOpenLogo(false); }
  const handleChangePassword = () => {
    const newPassword1 = document.getElementById('newPassword1').value;
    const newPassword2 = document.getElementById('newPassword2').value;

    if (!/^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/.test(newPassword1)) {
      SimpleToastWrapper({
        content: 'Minimum 8 characters at least 1 Alphabet, 1 Number and 1 Special Character.',
        type: 'error',
        alignItem: 'left',
      });
      return false;
    }
    if (newPassword1!==newPassword2) {
      SimpleToastWrapper({
        content: 'Please input same new password.',
        type: 'error',
        alignItem: 'left',
      });
      return false;
    }

    AccountActions.changePassword({newPassword: newPassword1, navigate, successHandler: ()=>{setOpen(false)}});
  }
  const handleChangeAccount = () => {

    var regName = document.getElementById('regName').value.trim();
    var regPhone = document.getElementById('regPhone').value.trim();
    var regEmail = document.getElementById('regEmail').value.trim();

    var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (regName === '') {
      SimpleToastWrapper({
        content: 'Name is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrName(true);
      document.getElementById('regName').focus();
      return false;
    } else {
      setErrName(false);
    }
    
    if (regPhone === '') {
      SimpleToastWrapper({
        content: 'Telephone number is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrPhone(true);
      document.getElementById('regPhone').focus();
      return false;
    } else {
      setErrPhone(false);
    }

    if (regEmail === '') {
      SimpleToastWrapper({
        content: 'Email is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrEmail(true);
      document.getElementById('regEmail').focus();
      return false;
    } else if (regEmail.match(regExp) === null) {

      SimpleToastWrapper({
        content: 'Please input correct email address.',
        type: 'error',
        alignItem: 'left',
      });
      setErrEmail(true);
      document.getElementById('regEmail').focus();
      return false;
    } else {
      setErrEmail(false);
    }

    var data = cloneDeep(account);
    data.name = regName;
    data.mobile = regPhone;
    data.email = regEmail;
    data.interests = interestItems.map(d=>{return{"interestCd":d,"isValid":true}})
    AccountActions.requestChangeAccount({ data });
  }
  const handleChangeLogo = () => {
    const file = document.getElementById('regCompanyLogo').files[0];

    if (file===undefined) {
      SimpleToastWrapper({
        content: 'Please upload LOGO image.',
        type: 'error',
        alignItem: 'left',
      });
      return false;
    }


    const formData = new FormData();
    formData.append('companyNo', account.companyNo);
    formData.append('logo', file, file.name);
    AccountActions.changeCompanyLogo({ formData: formData, navigate });
  };
  const onSetRegisterInterestClick = (data) => {
    if (interestItems.indexOf(data.cdDtlCd)===-1) {
      if (interestItems.length==3) {
        SimpleToastWrapper({
          content: 'Only can choose 3 items.',
          type: 'error',
          alignItem: 'left',
        });
        return false;
      }
      setInterestItems([...interestItems, data.cdDtlCd]);
    } else {
      setInterestItems(interestItems.filter(item => item!==data.cdDtlCd));
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    fetchCate1();
    if (account.name!==undefined && account.interests!=undefined) {
      setInterestItems([...account.interests.map(d=>d.interestCd)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, account]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/"
          src={(account.company!==undefined&&account.company.logo!==''&&account.company.logo!==null) ? 'https://euroscope-customer.s3.eu-central-1.amazonaws.com/'+account.company.logo : ''}
          sx={{
            border: '1',
            // borderColor: '#cccccc'
          }}
          style={{border: '1px solid #cccccc'}}
        />
        {
          account.name !== undefined && (
            <React.Fragment>
              <Typography
                className={classes.name}
                color="textPrimary"
                variant="h5"
              >
                {account.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {account.email}
              </Typography>
              <br/>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {account.company.name}
              </Typography>
            </React.Fragment>
          )
        }
      </Box>
      <Divider />
      <Box p={3} className="text-center">
        <Button variant="outlined" color="primary" onClick={handleClickOpenLogo} fullWidth startIcon={<ImageIcon />}>
          Company Logo
        </Button>
        <Dialog open={openLogo} onClose={handleCloseLogo} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Company Logo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can change your company logo.
            </DialogContentText>
            <input type="file" class="form-control-file" id="regCompanyLogo"  accept="image/png, image/gif, image/jpeg"></input>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseLogo} color="primary">
              Cancel
            </Button>
            <Button onClick={handleChangeLogo} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Divider />
      <Box p={1} className="text-center">
        <Button variant="contained" color="primary" onClick={handleClickOpenInform} fullWidth startIcon={<AccountCircleIcon />}>
          Change Information
        </Button>
        <Dialog open={openInform} onClose={handleCloseInform} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Account Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can change your information.
            </DialogContentText>
            {
              account.name !== undefined && (
                <React.Fragment>
                  <TextField
                    variant="outlined"
                    InputLabelProps={{shrink: true,}}
                    autoFocus
                    margin="dense"
                    id="regCompany"
                    label="Company Name"
                    required
                    fullWidth
                    disabled={true}
                    defaultValue={isNullStr(account.company.name)}
                  />
                  <TextField
                    variant="outlined"
                    InputLabelProps={{shrink: true,}}
                    autoFocus
                    margin="dense"
                    id="regAddress"
                    label="Company Address"
                    required
                    fullWidth
                    disabled={true}
                    defaultValue={isNullStr(account.company.address)}
                    error={Boolean(errAddress)}
                  />
                  <TextField
                    variant="outlined"
                    InputLabelProps={{shrink: true,}}
                    autoFocus
                    margin="dense"
                    id="regName"
                    label="Name"
                    required
                    fullWidth
                    defaultValue={isNullStr(account.name)}
                    error={Boolean(errName)}
                  />
                  <TextField
                    variant="outlined"
                    InputLabelProps={{shrink: true,}}
                    autoFocus
                    margin="dense"
                    id="regPhone"
                    label="Telephone Number"
                    required
                    fullWidth
                    defaultValue={isNullStr(account.mobile)}
                    error={Boolean(errPhone)}
                  />
                  <TextField
                    variant="outlined"
                    InputLabelProps={{shrink: true,}}
                    autoFocus
                    margin="dense"
                    id="regEmail"
                    label="Email Address"
                    type="email"
                    required
                    fullWidth
                    defaultValue={isNullStr(account.email)}
                    error={Boolean(errEmail)}
                  />
                  <InputLabel htmlFor="component-simple" shrink style={{margin: '15px 0px 10px'}}>Topics of interest ( {interestItems.length} / 3 )</InputLabel>
                  <Grid container spacing={2}>
                    {
                      cate1.map((data, index) => {
                        if (interestItems.indexOf(data.cdDtlCd)===-1) {
                          return (
                            <Grid item lg={4} md={6} xs={12}>
                              <Button variant="outlined" fullWidth={true} color="secondary" onClick={() => onSetRegisterInterestClick(data)}>{data.name}</Button>
                            </Grid>
                          );
                        }
                        else {
                          return (
                            <Grid item lg={4} md={6} xs={12}>
                              <Button variant="contained" fullWidth={true} color="secondary" onClick={() => onSetRegisterInterestClick(data)}>{data.name}</Button>
                            </Grid>
                          );
                        } 
                      })
                    }
                  </Grid>
                </React.Fragment>
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseInform} color="primary">
              Cancel
            </Button>
            <Button onClick={handleChangeAccount} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box p={1} className="text-center">
        <Button variant="contained" color="primary" onClick={handleClickOpen} fullWidth startIcon={<LockOpenIcon />}>
          Change password
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can change your password.
            </DialogContentText>
            <TextField
              variant="outlined"
              InputLabelProps={{shrink: true,}}
              autoFocus
              margin="dense"
              id="newPassword1"
              label="New Password"
              type="password"
              fullWidth
              inputProps={{ tabIndex: "1" }} 
            />
            <TextField
              variant="outlined"
              InputLabelProps={{shrink: true,}}
              margin="dense"
              id="newPassword2"
              label="New Password (Confirm)"
              type="password"
              fullWidth
              inputProps={{ tabIndex: "2" }} 
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleChangePassword} color="primary">
              Change
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
            <Button onClick={onLogoutHandler} color="primary">
              Logout
            </Button>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};
export default connect(
  (state, ownProps) => ({
    account: state.account,
  }),
  (dispatch) => ({ 
    AccountActions: bindActionCreators(AccountActions, dispatch),
  })
)(NavBar);
