export const APP_NAME = 'Euroscope FIT System.';
export const APP_VERSION = '1.0.0';

// export const SERVER_ADDR = 'http://localhost';
// export const SERVER_PORT = ':8081';
// export const SERVER_ADDR = 'https://b2bapi.euroscope.org';
// export const SERVER_PORT = '';
export const SERVER_ADDR = 'https://b2bapitest.euroscope.org';
export const SERVER_PORT = '';
// export const SERVER_ADDR = 'http://54.180.94.180';
// export const SERVER_PORT = '/api';
export const SERVER_URL = SERVER_ADDR + SERVER_PORT;

// export const ADMIN_ADDR = 'http://admin.euroscope.org';
export const ADMIN_ADDR = 'http://test.euroscope.org';

export const FORMAT_DATE = 'YYYY-MM-DD';

export const isNull = (field) => {
  return (field!==undefined) ? field : false;
}
export const isNullStr = (field) => {
  return (field!==undefined) ? field : "";
}