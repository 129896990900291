import React, { useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
  Typography,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';

import TableWrapper from 'src/components/Common/Grid/TableWrapper';
import * as GroupActions from 'src/reducers/Group/actions';

const TemporarySavedList = ({ classes, temporarySavedTourGroups, handleMethods, navigate, GroupActions, ...rest }) => {

  const [expanded, setExpanded] = useState(true);

  const openTemporarySavedTourGroup = (groupTempNo) => {
    window.open(`/group/edit?groupTempNo=${groupTempNo}`);
  }
  const deleteTemporarySavedTourGroup = (groupTempNo) => {
    GroupActions.deleteTemporarySavedTourGroup({ groupTempNo, navigate, successHandler: (data) => {
      handleMethods.handleSetTemporarySavedGroup(data);
    } });
  }

  const headCells = [
    { id: 'date', label: 'Date', width: '130px' },
    { id: 'groupCode', label: 'Group Code', align: 'left' },
    { id: 'delete', label: 'Delete', align: 'right' },
  ];
  const bodyCells = [
    { key: 'date', isOnlyChildren: true, children: (({ data }) => (
      <div onDoubleClick={()=>openTemporarySavedTourGroup(data.groupTempNo)}>
        <span style={{fontWeight: '500'}}>{moment(data.updatedDate).format('YY/MM/DD hh:mm')}</span>
        <br/><span>{data.updatedName}</span>
      </div>)) },
    { key: 'groupCode', align: 'left', isOnlyChildren: true, children: (({ data }) => (<div onDoubleClick={()=>openTemporarySavedTourGroup(data.groupTempNo)}><span>{data.groupCode}</span></div>)) },
    { key: 'delete', align: 'right', isOnlyChildren: true, children: (({ data }) => (
      <Tooltip title="Delete">
        <IconButton onClick={() => {deleteTemporarySavedTourGroup(data.groupTempNo)}} aria-label="Delete" size="small">
          <ClearIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )) },
  ];

  return (
    <Grid>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Temporary saved
            <br/><span style={{fontSize: '0.75rem', color: 'gray'}}>Double click to continue editing.</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableWrapper
            datas={temporarySavedTourGroups}
            headCells={headCells}
            bodyCells={bodyCells}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch) => ({
    GroupActions: bindActionCreators(GroupActions, dispatch)
  })
)(TemporarySavedList);

