import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import queryString from 'query-string';

import PersonAddIcon from '@material-ui/icons/PersonAdd';

import { SimpleToastWrapper } from 'src/components/Common';
import * as AccountActions from 'src/reducers/Account/actions';

import * as CommonServices from 'src/services/CommonServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = ({ AccountActions, history }) => {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [errCompany, setErrCompany] = useState(false);
  const [errPhone, setErrPhone] = useState(false);
  const [errAddress, setErrAddress] = useState(false);
  const [errName, setErrName] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [chkTerm, setChkTerm] = useState(false);
  const [errTerm, setErrTerm] = useState(false);
  const [interestItems, setInterestItems] = useState([]);

  const [cate1, setCate1] = useState([]);


  const handleClickOpen = () => { setOpen(true); }
  const handleClose = (event, reason) => { 
    if (reason !== 'backdropClick') {
      setOpen(false);
    } }
  const handleCreateAccount = () => {

    var regCompany = document.getElementById('regCompany').value.trim();
    var regPhone = document.getElementById('regPhone').value.trim();
    var regAddress = document.getElementById('regAddress').value.trim();
    var regName = document.getElementById('regName').value.trim();
    var regEmail = document.getElementById('regEmail').value.trim();
    var regMemo = document.getElementById('regMemo').value.trim();

    var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!chkTerm) {
      SimpleToastWrapper({
        content: 'Please check term and condition.',
        type: 'error',
        alignItem: 'left',
      });
      document.getElementById('chkTerm').focus();
      setErrTerm(true);
      return false;
    } else {
      setErrTerm(false);
    }

    if (regCompany === '') {
      SimpleToastWrapper({
        content: 'Company name is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrCompany(true);
      document.getElementById('regCompany').focus();
      return false;
    } else {
      setErrCompany(false);
    }

    if (regPhone === '') {
      SimpleToastWrapper({
        content: 'Telephone number is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrPhone(true);
      document.getElementById('regPhone').focus();
      return false;
    } else {
      setErrPhone(false);
    }

    if (regAddress === '') {
      SimpleToastWrapper({
        content: 'Company address is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrAddress(true);
      document.getElementById('regAddress').focus();
      return false;
    } else {
      setErrAddress(false);
    }

    if (regName === '') {
      SimpleToastWrapper({
        content: 'Name is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrName(true);
      document.getElementById('regName').focus();
      return false;
    } else {
      setErrName(false);
    }
    
    if (regEmail === '') {
      SimpleToastWrapper({
        content: 'Email is required.',
        type: 'error',
        alignItem: 'left',
      });
      setErrEmail(true);
      document.getElementById('regEmail').focus();
      return false;
    } else if (regEmail.match(regExp) === null) {

      SimpleToastWrapper({
        content: 'Please input correct email address.',
        type: 'error',
        alignItem: 'left',
      });
      setErrEmail(true);
      document.getElementById('regEmail').focus();
      return false;
    } else {
      setErrEmail(false);
    }

    var data = {
      companyName: regCompany,
      companyPhone: regPhone,
      companyAddress: regAddress,
      usrName: regName,
      usrMail: regEmail,
      memo: regMemo,
      interests: interestItems.map((d)=>{return {'cdDtlCd':d, 'cdDtlName': cate1.filter(i=>i.cdDtlCd==d)[0].name}}),
    }
    AccountActions.requestNewAccount({ data, successHandler: setOpen});
  }
  const onSetRegisterInterestClick = (data) => {
    if (interestItems.indexOf(data.cdDtlCd)===-1) {
      if (interestItems.length==3) {
        SimpleToastWrapper({
          content: 'Only can choose 3 items.',
          type: 'error',
          alignItem: 'left',
        });
        return false;
      }
      setInterestItems([...interestItems, data.cdDtlCd]);
    } else {
      setInterestItems(interestItems.filter(item => item!==data.cdDtlCd));
    }
  };

  const query = queryString.parse(location.search);
  const targetUrl = (query.targetUrl===undefined || query.targetUrl==='') ? '/' : query.targetUrl;

  const fetchCate1 = async () => {
    const datas = await CommonServices.getCodes({ groupId: 53 });
    setCate1(datas.data.cdMstTb.cdDtlTbs);
  };

  React.useEffect(() => {
    AccountActions.checkLogin({isRedirect:true, flagWhenRedirect:true, targetUrl, navigate});
    fetchCate1();
  }, []);

  const STRINGS = {
    title: "Login to Euroscope B2B System",
    buttonText: "Login",
    emailLabel: "Email Address",
    emailPlaceholder: "Enter email",
    passwordLabel: "Password",
    passwordPlaceholder: "Password",
  };

  return (
    <Page
      className={classes.root}
      title={STRINGS.title}
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validate={values => {
              // same as above, but feel free to move this into a class method now.
              let errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={(
              values,
              { setSubmitting, setErrors /* setValues and other goodies */ }
            ) => {
              AccountActions.login({ user: values, targetUrl, navigate });
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Box my={2}>
                  <Button variant="contained" size="large" color="primary" style={{backgroundColor: '#5f2135'}} onClick={handleClickOpen} fullWidth startIcon={<PersonAddIcon />}>
                  Request New Account
                  </Button>
                  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                    <DialogTitle id="form-dialog-title">Request new account</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        You can request new account.
                      </DialogContentText>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{shrink: true,}}
                        autoFocus
                        margin="dense"
                        id="regCompany"
                        label="Company Name"
                        required
                        fullWidth
                        error={Boolean(errCompany)}
                      />
                      <TextField
                        variant="outlined"
                        InputLabelProps={{shrink: true,}}
                        autoFocus
                        margin="dense"
                        id="regAddress"
                        label="Company Address"
                        required
                        fullWidth
                        error={Boolean(errAddress)}
                      />
                      <TextField
                        variant="outlined"
                        InputLabelProps={{shrink: true,}}
                        autoFocus
                        margin="dense"
                        id="regName"
                        label="Name"
                        required
                        fullWidth
                        error={Boolean(errName)}
                      />
                      <TextField
                        variant="outlined"
                        InputLabelProps={{shrink: true,}}
                        autoFocus
                        margin="dense"
                        id="regPhone"
                        label="Telephone Number"
                        required
                        fullWidth
                        error={Boolean(errPhone)}
                      />
                      <TextField
                        variant="outlined"
                        InputLabelProps={{shrink: true,}}
                        autoFocus
                        margin="dense"
                        id="regEmail"
                        label="Email Address"
                        type="email"
                        required
                        fullWidth
                        error={Boolean(errEmail)}
                      />
                      <TextField
                        variant="outlined"
                        InputLabelProps={{shrink: true,}}
                        autoFocus
                        margin="dense"
                        id="regMemo"
                        label="Memo"
                        fullWidth
                      />
                      <InputLabel htmlFor="component-simple" shrink style={{margin: '15px 0px 10px'}}>Topics of interest ( {interestItems.length} / 3 )</InputLabel>
                      <Grid container spacing={2}>
                        {
                          cate1.map((data, index) => {
                            if (interestItems.indexOf(data.cdDtlCd)===-1) {
                              return (
                                <Grid item lg={4} md={6} xs={12}>
                                  <Button variant="outlined" fullWidth={true} color="secondary" onClick={() => onSetRegisterInterestClick(data)}>{data.name}</Button>
                                </Grid>
                              );
                            }
                            else {
                              return (
                                <Grid item lg={4} md={6} xs={12}>
                                  <Button variant="contained" fullWidth={true} color="secondary" onClick={() => onSetRegisterInterestClick(data)}>{data.name}</Button>
                                </Grid>
                              );
                            } 
                          })
                        }
                      </Grid>

                        <FormControl required error={errTerm} component="fieldset" className={classes.formControl}>
                        <Typography
                          variant="body1"
                          style={{paddingTop:'30px'}}
                        >
                          개인정보보호법 제15조(개인정보의 수집, 이용), 제 17조(개인정보의 제공), 제18조(개인정보의 이용 및 제공 제한, 제22조(동의를 받는 방법) 에 의거 개인정보처리에 관하여 고지를 받았으며 본인은 위와 같이 개인정보 수집 및 이용,제공에 동의 합니다. <br/>
                          <br/>
                          * 개인정보 이용, 제공에 관항 동의 사항<br/>
                          수집된 개인정보가 향후 이용, 제공, 활용이 될 경우에는 아래와 같이 필요한 사항을 알리겠습니다. <br/>
                          <br/>
                          1) 개인정보를 제공받는 자<br/>
                          2) 개인정보를 제공받는 자의 이용 목적<br/>
                          3) 제공하는 개인정보의 항목<br/>
                          4) 개인정보를 제공받은 자의 개인정보 보유 및 이용기간<br/>
                          5) 동의 거부 권리 및 동의 거부에 따른 불이익 내용 또는 제한사항<br/>
                          <br/>
                          <Box
                            alignItems="center"
                            display="flex"
                            style={{paddingTop:'10px'}}
                            ml={1}
                          >
                            <Checkbox
                              id="chkTerm"
                              checked={chkTerm}
                              name="policy"
                              onChange={(event) => setChkTerm(event.target.checked)}
                              error={Boolean(errTerm)}
                            />
                            위의 사항에 대해 동의 합니다.  
                          </Box>
                        </Typography>
                        { errTerm && (<FormHelperText>&nbsp;&nbsp;* Terms and condition is required.</FormHelperText>) }
                        </FormControl>
                        
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Close
                      </Button>
                      <Button onClick={handleCreateAccount} color="primary">
                        Send
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              </form>
            )}
          />
        </Container>
      </Box>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({
    isLoading: state.isLoading,
  }),
  (dispatch) => ({
    AccountActions: bindActionCreators(AccountActions, dispatch),
  })
)(LoginView);
