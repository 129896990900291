import React from 'react';

import TextType from './TextType';
import SelectType from './SelectType';
import SwitchType from './SwitchType';
import DatePickerType from './DatePickerType';
import TimePickerType from './TimePickerType';
import CityType from './CityType';
import RoomTypeWrapper from './RoomTypeWrapper';
import RoomType from './RoomType';
import WrapperType from './WrapperType';

const BasicInform = ({index, field}) => {
  // Size setting.
  if (field.xs===undefined && field.md===undefined && field.lg===undefined) {
    field.lg = 3;
    field.md = 6;
    field.xs = 12;
  }
  const params = {
    key: index,
    index: index,
    field: field,
  }

  switch (field.t) {
    case "text":
      return (<TextType {...params} />);
    case "select":
      return (<SelectType {...params} />);
    case "switch":
      return (<SwitchType {...params} />);
    case "rangeDatePicker":
      return (<DatePickerType {...params} />);
    case "timePicker":
      return (<TimePickerType {...params} />);
    case "location":
      return (<CityType {...params} />);
    case "roomTypeWrapper":
      return (<RoomTypeWrapper {...params} />);
    case "roomTypeList":
      return (<RoomType {...params} />);
    case "wrapper":
      return (
        <WrapperType {...params}>
          {field.children.map((cField, cIndex) => (
            <BasicInform
              index={cIndex}
              field={cField}
            />
          ))}
        </WrapperType>
      )
    default:

      return (<span> </span>)
  }
}

BasicInform.Text = TextType;
BasicInform.Select = SelectType;

export default BasicInform;
export {
  TextType,
  SelectType,
};