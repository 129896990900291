import React from 'react';

import moment from 'moment';

import {
  Badge,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import { ADMIN_ADDR } from 'src/components/Common/Constants';
import ChangeStateBtn from './ChangeStateBtn';

const OpenAdminPopup = ({ chkValue, title, tooltip, url, style, icon }) => {
  return (
    chkValue===true ? 
    (
      <Tooltip title={title}>
        <IconButton onClick={() => {window.open(url, title, style)}} aria-label={title} size="small">
          {icon}
        </IconButton>
      </Tooltip>
    ) :
    (
      <span> </span>
    )
  )
}

  const headCells = [
    { id: 'reqDate', label: 'Req Date', width:'120px', isSortable:true }, 
    { id: 'groupCode', label: 'GroupCode', align: 'left', isSortable:true },
    { id: 'startDate', label: 'Start Date', width:'120px', isSortable:true },
    { id: 'endDate', label: 'End Date', width:'120px' },
    { id: 'period', label: 'Day', align: 'right', width:'30px' },
    { id: 'paxNo', label: 'Pax', align: 'right', width:'30px' },
    { id: 'previewBtn', label: 'Semi plan', width:'50px' },
    { id: 'finalBtn', label: 'Final plan', width:'50px' },
    { id: 'hotelListBtn', label: 'Hotel list', width:'50px' },
    { id: 'quotationBtn', label: 'Quotation', width:'50px' },
    { id: 'chgState', label: 'Status', width:'60px' },
    { id: 'historyBtn', label: 'History', width:'20px' },
  ]

  const bodyCells = [
    { key: 'reqDate', children: (({ data }) => (<span style={{fontSize: '13px'}}><br/>{ data.reqDate!==null && (<span>({moment(new Date()).diff(moment(data.reqDate, 'YYYY-MM-DD'), 'days')} days before)</span>) }</span>)) }, 
    { key: 'groupCode', isOnlyChildren: true, children: (({ data }) => (
      <div onDoubleClick={() => { window.open(`/group/${data.groupNo}`) }}>
        <span style={{width: '100px', fontSize: '13px'}}>{data.groupCode}</span>
      </div>
      )), align: 'left' },
    { key: 'startDate', children: (({ data }) => (<span style={{fontSize: '13px'}}><br/>({data.startLocation})</span>)) },
    { key: 'endDate', children: (({ data }) => (<span style={{fontSize: '13px'}}><br/>({data.endLocation})</span>)) },
    { key: 'period', align: 'right' },
    { key: 'paxNo', align: 'right' },
    { key: 'previewBtn', isOnlyChildren: true, children: (({ data })=>(
      <OpenAdminPopup 
        chkValue={data.sentPreview}
        title={`Preview of ${data.groupCode}`}
        url={`${ADMIN_ADDR}/b2b/preview/finalPlan/${data.groupNo}`}
        style={`width=815, height=1000,location=no,status=no,scrollbars=yes`}
        icon={(<OpenInBrowserIcon fontSize="inherit" />)}
      />
      )) },
    { key: 'finalBtn', isOnlyChildren: true, children: (({ data })=>(
      <OpenAdminPopup 
        chkValue={data.sentFinal}
        title={`Download final plan of ${data.groupCode}`}
        url={`${ADMIN_ADDR}/planPreview2/export/word/${data.groupNo}`}
        style={``}
        icon={(<CloudDownloadIcon fontSize="inherit" />)}
      />
      )) },
    { key: 'hotelListBtn', isOnlyChildren: true, children: (({ data })=>(
      <OpenAdminPopup 
        chkValue={data.fixGroup===1}
        title={`Hotel list of ${data.groupCode}`}
        url={`${ADMIN_ADDR}/b2b/preview/hotelList/${data.groupNo}`}
        style={`width=815, height=1000,location=no,status=no,scrollbars=yes`}
        icon={(<OpenInBrowserIcon fontSize="inherit" />)}
      />
      )) },
    { key: 'quotationBtn', isOnlyChildren: true, children: (({ data })=>{
      let division = 'eng';
      if (data.nationality==='31-1') division = 'kor';
      if (data.nationality==='31-2') division = 'jpn';
      return (
        <OpenAdminPopup 
          chkValue={data.sentFinal}
          title={`Download quotation document of ${data.groupCode}`}
          url={`${ADMIN_ADDR}/tourGroup/estimationDocument/export/word/${data.groupNo}/${division}`}
          style={``}
          icon={(<CloudDownloadIcon fontSize="inherit" />)}
        />
      )}) },
    { key: 'stateBtn', isOnlyChildren: true, children: (({ id, data, datas, setDatas, handleMethods })=>( <ChangeStateBtn id={id} data={data} handleMethods={handleMethods} /> ))},
    { key: 'historyBtn', isOnlyChildren: true, children: (({ data, datas, setDatas })=>{
      return (
        <Tooltip title="View history">
          <Badge badgeContent={data.newHistory} color="error">
            <IconButton onClick={() => {setDatas(datas.map((item) => { return item.groupNo===data.groupNo ? {...item, newHistory: 0} : {...item}}));window.open(`/popup/group/${data.groupNo}/comments`, `History of ${data.groupCode}`, `width=1000, height=800,location=no,status=no,scrollbars=yes`)}} aria-label="History open" size="small">
              <AllInboxIcon fontSize="inherit" />
            </IconButton>
          </Badge>
        </Tooltip>
      )}) },
  ];


export const ArrangeCell = {
  HeadCell: headCells.slice(0,9).concat(headCells.slice(11,12)),
  BodyCell: bodyCells.slice(0,9).concat(bodyCells.slice(11,12)),
};

export const RequestCell = {
  HeadCell: headCells.slice(0,6).concat(headCells.slice(9,12)),
  BodyCell: bodyCells.slice(0,6).concat(bodyCells.slice(9,12)),
};

export const CancelCell = {
  HeadCell: headCells.slice(0,6).concat(headCells.slice(11,12)),
  BodyCell: bodyCells.slice(0,6).concat(bodyCells.slice(11,12)),
};