import React from 'react';
import {
  Chip,
  Grid,
  InputLabel,
  Paper,
  makeStyles,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import ImageIcon from '@material-ui/icons/Image';

import './AttatchedInformation.css';

const useStyles = makeStyles((theme) => ({
  root: {},
  fileDropWrapper: {
    padding: theme.spacing(1, 1.5)
  },
  fileDropzone: {
    display: 'flex',
    height: '188px',
  },
  fileListGrid: {
    paddingTop: theme.spacing(2)
  },
  fileListWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    // padding: theme.spacing(1, 1.5),
    margin: theme.spacing(1,1.5),
    minHeight: '150px',
  },
  file: {
    margin: theme.spacing(0.5),
  },
}));

const AttatchedInformation = ({ attatchedList, setAttatchedList }) => {
  const classes = useStyles();

  const handleDrop = (files) => {
    setAttatchedList([...attatchedList, ...files]);
  }
  const handleDropReject = (files) => {
    console.log(files);
  }
  const handleDelete = (delFile) => () => {
    setAttatchedList(
      attatchedList.filter(file => file.path !== delFile.path)
    )
  }

  const files = attatchedList.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <React.Fragment>
      <Grid container>
        <Grid item lg={12} md={4} xs={12}>
          <div className={classes.fileDropWrapper}>
            <Dropzone 
              onDrop={handleDrop} 
              onDropRejected={handleDropReject}
              accept={
                "image/*,"+
                ".pdf,"+
                ".zip,"+
                ".hwp,"+
                "application/haansoftdoc,"+
                "application/haansoftdocx,"+
                "application/haansoftxls,"+
                "application/haansoftxlsx,"+
                "application/haansoftppt,"+
                "application/haansoftpptx,"+
                "application/vnd.ms-excel,"+
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,"+
                "application/msword,"+
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document,"+
                "application/vnd.ms-powerpoint,"+
                "application/vnd.openxmlformats-officedocument.presentationml.slideshow,"+
                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
              }
            >
              {({getRootProps, getInputProps, fileRejections}) => (
                <section className={classes.fileDropzone}>
                  <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()}/>
                    <CloudUploadIcon />
                    <p>File upload here</p>
                    {/* <em>(Only *.jpeg and *.png images, word, excel, powerpoint, pdf, zip will be accepted)</em> */}
                    {
                      fileRejections.length !== 0 && (
                          <span style={{color:'red'}}><br/>Please check file type.</span>
                      )
                    }
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </Grid>
        <Grid item lg={12} md={8} xs={12} className={classes.fileListGrid}>
          <InputLabel htmlFor="component-simple" shrink style={{paddingLeft:'10px'}}>Upload list ({attatchedList.length})</InputLabel>
          <Paper component="ul" className={classes.fileListWrapper}>
            { attatchedList.map((file, index) => {
              let icon;

              if (file.type === 'application/pdf') {
                icon = <DescriptionIcon size="small" />;
              } else if (file.type.startsWith('image/')) {
                icon = <ImageIcon size="small" />
              } else if (file.type === 'application/zip') {
                icon = <FolderIcon size="small" />
              } else {
                icon = <DescriptionIcon size="small" />;
              }

              return (
                <li key={index}>
                  <Chip
                    size="small"
                    icon={icon}
                    label={file.path}
                    onDelete={handleDelete(file)}
                    className={classes.file}
                  />
                </li>
              )
            })}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AttatchedInformation;