import * as Actions from './actions';

export function groupBasicInformation(state = {}, action) {
  switch (action.type) {
    case Actions.SET_BASIC_INFORMATION:
      return action.basicInformation;
    default:
      return state;
  }
};

export function groupComments(state = [], action) {
  switch (action.type) {
    case Actions.SET_GROUP_COMMENTS:
      return action.comments;
    default:
      return state;
  }
}
export function groupComment(state = {}, action) {
  switch (action.type) {
    case Actions.SET_GROUP_COMMENT:
      return action.comment;
    default:
      return state;
  }
}

export function hotelList(state = [], action) {
  switch (action.type) {
    case Actions.SET_HOTEL_LIST:
      return action.hotelList;
    case Actions.SET_HOTEL_LIST_RQ_FIXED:
      return state.map((item) => { return item.hotelListNo===action.hotelListNo ? {...item, isRQFixed: action.rqFixed} : {...item}});
    default:
      return state;
  }
};