
import * as SupplierService from "src/services/SupplierServices";
import { SimpleToastWrapper } from 'src/components/Common';

import { requestWrapper } from '../actionWrapper';

export const getAttractions = ({ name, country, city, type, successHandler, navigate }) => (dispatch, getState) => { 
  dispatch(requestWrapper(
    SupplierService.getAttractions({ name, country, city, type }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};

export const getTechnicalVisits = ({ name, country, city, cate1, cate2, successHandler, navigate }) => (dispatch, getState) => { 
  dispatch(requestWrapper(
    SupplierService.getTechnicalVisits({ name, country, city, cate1, cate2 }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};

export const getThemes = ({ name, country, city, cate1, cate2, cate3, successHandler, navigate }) => (dispatch, getState) => { 
  dispatch(requestWrapper(
    SupplierService.getThemes({ name, country, city, cate1, cate2, cate3 }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};

export const getContents = ({ supplierNo, successHandler, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    SupplierService.getContents({ supplierNo }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};

export const createSupplier = ({ supplier, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    SupplierService.createSupplier({ supplier }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Create success',
          type: 'success',
          alignItem: 'left',
        });

        if (successHandler!==undefined) {
          successHandler();
        }
      } else {
        SimpleToastWrapper({
          content: `Create failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};