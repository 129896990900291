import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
// import routes from 'src/routes';

import MainLayout from 'src/layouts/MainLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';

import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';

import GroupCommentList from 'src/views/group/Comment/GroupCommentList';
import GroupCommentAdd from 'src/views/group/Comment/GroupCommentAdd';
import TourGroupList from 'src/views/group/TourGroupList';
import TourGroupDetail from 'src/views/group/Detail'
import HotelListView from 'src/views/group/HotelList';
import EditGroupView from 'src/views/group/Edit';

import AttractionListView from 'src/views/supplier/Attraction';
import TechnicalVisitListView from 'src/views/supplier/TechnicalVisit';
import ThemeListView from 'src/views/supplier/Theme';
import ThemeAddView from 'src/views/supplier/Theme/Add';
import ContentsPopup from 'src/views/supplier/Contents';

import configureStore from './reducers/configureStore';
import './style.css';

const App = () => {
  // const routing = useRoutes(routes);

const store = configureStore();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {/* {routing} */}
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="group" element={<DashboardLayout />} >
              <Route exact path="hotels" element={<HotelListView />} />
              <Route exact path="edit" element={<EditGroupView />} />
              <Route exact path="list" element={<TourGroupList />} />
              <Route exact path=":groupNo" element={<TourGroupDetail />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Route>
            <Route path="supplier" element={<DashboardLayout />} >
              <Route exact path="attractions" element={<AttractionListView />} />
              <Route exact path="tv" element={<TechnicalVisitListView />} />
              <Route exact path="theme" element={<ThemeListView />} />
              <Route exact path="theme/add" element={<ThemeAddView />} />
              <Route path="*" element={<Navigate to="/404" />} />
            </Route>
            <Route exact path="popup" element={<MainLayout />}>
              <Route path="group/:groupNo/comments" element={<GroupCommentList />}/>
              <Route path="group/:groupNo/comment/:commentNo" element={<GroupCommentAdd />}/>
              <Route path="supplier/contents/:supplierNo" element={<ContentsPopup />}/>
            </Route>
            <Route exact path="/" element={<MainLayout />}>
              <Route path="/" element={<Navigate to="/group/list" />} />
              <Route path="login" element={<LoginView />}/>
              <Route path="404" element={<NotFoundView />}/>
              <Route path="*" element={<Navigate to="/404" />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>    
    </ThemeProvider>
  );
};

export default App;
