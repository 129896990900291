import axios from 'axios';

import { SERVER_URL } from 'src/components/Common/Constants';

export function login(user) {
  return axios(`${SERVER_URL}/user/login`,{method: 'post',data: user,withCredentials: true,}, );
}
export function logout() {
  return axios(`${SERVER_URL}/user/logout`,{ method: 'get', withCredentials: true });
}

export function getAccount() {
  return axios(`${SERVER_URL}/user/profile`,{ method: 'get', withCredentials: true });
}

export function loginCheck() {
  return axios(`${SERVER_URL}/user/loginCheck`,{ method: 'get', withCredentials: true });
}

export function changePassword({ newPassword }) {
  const formData = new FormData();
  formData.append('np', newPassword);

  return axios(`${SERVER_URL}/user/changePassword`,{method: 'post',data: formData,withCredentials: true,}, );
}

export function requestNewAccount({ data }) {
  return axios(`${SERVER_URL}/b2b/account/requestNewAccount`,{ method: 'post', data: data, withCredentials: true });
}

export function requestChangeAccount({ data }) {
  return axios(`${SERVER_URL}/b2b/account/requestChangeAccount`,{ method: 'post', data: data, withCredentials: true });
}
export function changeCompanyLogo({ formData }) {
  return axios(`${SERVER_URL}/b2b/account/changeCompanyLogo`,{ method: 'post', data: formData, withCredentials: true });
}