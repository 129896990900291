import React from 'react';

import {
  Grid,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
} from '@material-ui/core';

import ButtonBox from './Item/ButtonBox';
import CustomTextField from './Item/CustomTextField';
import ItineraryList from './Item/ItineraryList';
import FileList from './Item/FileList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '6px',
    paddingLeft: '15px',
  },
  chipWrapper: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  accordionArrow: {
    padding: '0px'
  },
  accordionContent: {
    display: 'block',
  },

  commentBorder: {
    border: "1px solid rgb(189, 189, 189)", 
    borderRadius: "6px", 
    width: "95%", 
    margin: "auto", 
    padding: "10px 20px"
  }
}));

const DetailWrapper = ({ groupNo, groupCode, basicInformation, attatchedList, commentAttatchedList, datas }) => {
  const classes = useStyles();

  const fields = [
    { type:'text', name:'groupCode', value:groupCode, label:'Group Code' },
    { type:'text', name:'groupType', value:datas.groupTypeName, label:'Group Type' },
    { type:'text', name:'operator', value:datas.operatorName, label:'Operator' },
    { type:'text', name:'groupGrade', value:datas.groupGradeName, label:'Hotel Grade' },

    { type:'text', name:'startDate', value:datas.startDate, label:'Start Date' },
    { type:'text', name:'endDate', value:datas.endDate, label:'End Date' },
    { type:'text', name:'startTime', value:datas.startTime, label:'Start Time' },
    { type:'text', name:'endTime', value:datas.endTime, label:'End Time' },

    { type:'text', name:'startLocation', value:(datas.startLocation!==undefined&&datas.startLocation.label), label:'Start Location' },
    { type:'text', name:'endLocation', value:(datas.endLocation!==undefined&&datas.endLocation.label), label:'End Location' },
    { type:'text', name:'startFlightNo', value:datas.startFlightNo, label:'Start Flight Nr.' },
    { type:'text', name:'endFlightNo', value:datas.endFlightNo, label:'End Flight Nr.' },

    { type:'text', name:'paxNo', value:datas.paxNo, label:'Pax Nr.' },
    { type:'text', name:'nationality', value:datas.nationalityName, label:'Nationality' },
    { type:'text', name:'transport', value:datas.transportName, label:'Transport Type' },
    { type:'text', name:'guideType', value:datas.guideTypeName, label:'Guide Type' },

    
    { type:'roomType', name:'roomType', value:datas.roomList, label:'Room Types' },
    { type:'switch', name:'noHotelService', value:datas.isNoHotel, label:'No hotel request' },
    { type:'switch', name:'noBusService', value:datas.isNoBus, label:'No bus request' },
    { type:'switch', name:'noRestaurantService', value:datas.isNoRestaurant, label:'No restaurant request' },
  ];

  const getStartLocation = (index) => {
    if (index===0) {
      return datas.startLocation;
    } else if (datas.itineraryList[index-1].cities.length===0) {
      return getStartLocation(index-1);
    } else {
      return datas.itineraryList[index-1].cities[datas.itineraryList[index-1].cities.length-1];
    }
  }

  return (
    <React.Fragment>
      <CardHeader
        title="Basic Information"
        subheader="Requested information"
        className="basicInformationHeader"
        style={{display:'block'}}
        action={
          <ButtonBox
            groupNo={groupNo}
            groupCode={groupCode}
            basicInformation={basicInformation}
          />
        }
      />
      <Divider />
      <Divider />
      <div style={{paddingBottom:"30px"}}></div>
      <CardContent>
        <Grid container spacing={3} >
          { 
            fields.map((field, index) => (<CustomTextField key={index} field={field} index={index+1} />))
          }
        </Grid>
      </CardContent>
      <CardContent>
        <CardHeader
          title="Simple Plan"
        />
        <Grid container spacing={3} >
          <div style={{paddingBottom:"15px", width: '100%'}}></div>
          {
            datas.itineraryList!==undefined && datas.itineraryList.map((data,index) => {
              const startCity = getStartLocation(index);
              return (
                <ItineraryList
                  key={index}
                  index={index}
                  noRestaurantService={datas.isNoRestaurant}
                  noHotelService={datas.isNoHotel}
                  data={data}
                  startLocation={startCity}
                  endLocation={datas.endLocation}
                  isStart={index===0}
                  isEnd={index===datas.itineraryList.length-1}
                />
              );
            })
          }
        </Grid>
      </CardContent>
      <CardContent>
        <CardHeader
          title="Comment"
        />
        <Grid container spacing={3} >
          <div style={{paddingBottom:"15px", width: '100%'}}></div>
          <div className={classes.commentBorder}>
            <div dangerouslySetInnerHTML={ {__html: datas.comment} }>
            </div>
          </div>
        </Grid>
      </CardContent>
      <CardContent>
        <CardHeader
          title="Attatched list"
        />
        <Grid container spacing={3} >
          <div style={{paddingBottom:"15px", width: '100%'}}></div>
          <FileList
            files={attatchedList}
            commentFiles={commentAttatchedList}
          />
        </Grid>
      </CardContent>
      <div style={{paddingBottom:"30px"}}></div>
      <ButtonBox
        groupNo={groupNo}
        groupCode={groupCode}
        basicInformation={basicInformation}
      />
    </React.Fragment>
  );
};

export default DetailWrapper;
