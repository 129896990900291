import React, { useState } from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';

import { isNull } from "src/components/Common/Constants";

import * as GroupServices from 'src/services/GroupServices';

const CityType = ({ index, field }) => {
  const [datas,setDatas] = useState([]);

  const onInputChange = debounce(async ( value, reason ) => {
    const lcValue = value.toLowerCase();

    if (value.length >= 2 && reason === 'input') {
      const datas = await GroupServices.getCitiesAndAirport({cityName: lcValue});
      setDatas(datas.data.cities);
    }
  }, 500);
  const filterOptions = (options, { inputValue }) => {return options;};

  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <Autocomplete
          id={field.id}
          name={field.id}
          groupBy={(option) => option.groupKey}
          getOptionLabel={(option) => option.label}
          value={field.v}
          options={datas}
          clearOnBlur={false}
          filterOptions={filterOptions}
          onChange={(event, value, reason) => field.m(value)}
          onInputChange={(event, value, reason) => onInputChange(value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={field.l}
              size="small"
              InputLabelProps={{ shrink: true, }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Grid>    
  );
}

export default CityType;