import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Badge,
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import Page from 'src/components/Page';
import Toolbar from 'src/components/Supplier/TechnicalVisit/Toolbar';
import ExpandTableWrapper from 'src/components/Common/Grid/ExpandTableWrapper';

import * as SupplierActions from 'src/reducers/Supplier/actions';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const TechnicalVisitList = (props) => {
  const classes = useStyles();

  const [technicalVisits, setTechnicalVisits] = useState([]);
  const navigate = useNavigate();
  
  const { SupplierActions } = props;

  const handleSearch = () => {
    const name = document.getElementById('name').value.toLowerCase();
    const country = document.getElementById('country').value;
    const city = document.getElementById('city').value.split(",")[0];
    const cate1 = document.getElementById('cate1').value;
    const cate2 = document.getElementById('cate2').value;

    SupplierActions.getTechnicalVisits({name, country, city, cate1, cate2, navigate, successHandler: (data) => {
      setTechnicalVisits(data.technicalVisits);
    }});
  };

  const headCells = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'countryName', label: 'Country', width: '180px', align: 'right' },
    { id: 'cityName', label: 'City/Location', width: '180px', align: 'right' },
    { id: 'cate1', label: 'Category 1', width: '160px', align: 'right' },
    { id: 'cate2', label: 'Category 2', width: '160px', align: 'right' },
    { id: 'tags', label: 'Tags', width: '160px', align: 'right' },
    { id: 'themes', label: 'Themes', width: '160px', align: 'right' },
    { id: 'comment', label: 'Comment', width: '230px', align: 'right' },
    { id: 'contents', label: 'Contents', width: '60px' },
    { id: 'website', label: 'Website', width: '60px' },
  ];
  const bodyCells = [
    { key: 'name', align: 'left' },
    { key: 'countryName', align: 'right' },
    { key: 'cityName', align: 'right', children: (({ data })=>{
      return (
        (data.place!==null && data.cityName!==data.place.trim() && data.place.trim()!=='') && (<span><br/>( {data.place} )</span>)
      )
    }) },
    { key: 'cate1', align: 'right' },
    { key: 'cate2', align: 'right' },
    { key: 'tagString', align: 'right' },
    { key: 'themeString', align: 'right' },
    { key: 'comment', align: 'right' },
    { key: 'contents', isOnlyChildren: true, children: (({ data })=>{
      return (
        data.contentsNum != 0 && (
          <Tooltip title="View contents">
          <Badge badgeContent={data.newHistory} color="error">
            <IconButton onClick={() => {window.open(`/popup/supplier/contents/${data.supplierNo}`, `Contents of ${data.name}`, `width=750, height=1200,location=no,status=no,scrollbars=yes`)}} aria-label="Contents open" size="small">
              <AllInboxIcon fontSize="inherit" />
            </IconButton>
          </Badge>
        </Tooltip>
        )
    )}) },
    { key: 'website', isOnlyChildren: true, children: (({ data })=>{
      return (
        data.website != null && (
          <Tooltip title="Website">
            <IconButton onClick={() => {window.open(data.website)}} aria-label="Website" size="small">
              <OpenInBrowserIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )
    )}) },
  ];

  return (
    <Page
      className={classes.root}
      title="Technical visit list."
    >
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth={false}>
          <Toolbar 
            onClickSearch={handleSearch}
          />
          <div
            draggable
            onDragStart={e => {e.preventDefault(); e.stopPropagation(); return false;}} 
            onContextMenu={(e)=>  {e.preventDefault(); e.stopPropagation(); return false;}}
          >
            <ExpandTableWrapper 
              title="Technical visit list"
              datas={technicalVisits}
              headCells={headCells}
              bodyCells={bodyCells}
              contentFormat={(data) => {
                return (
                <div>
                  {
                    (data.generalInfo!==null && data.generalInfo!==undefined && data.generalInfo.trim()!=='') && (
                      <React.Fragment>
                        <h2>[General Information]</h2>
                        <div dangerouslySetInnerHTML={ {__html: data.generalInfo} }  style={{padding: '5px 15px'}}/>
                      </React.Fragment>
                    )
                  }
                </div>
              )}}
              isNumbering={true}
            />
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({
  }),
  (dispatch) => ({
    SupplierActions: bindActionCreators(SupplierActions, dispatch),
  })
)(TechnicalVisitList);
