import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';

import ItineraryItem from './ItineraryItem';
import ItineraryItemCities from './ItineraryItemCities';
import ItineraryItemComment from './ItineraryItemComment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '6px',
    paddingLeft: '15px',
  },
  chipWrapper: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  accordionArrow: {
    padding: '0px'
  },
  accordionContent: {
    display: 'block',
  }
}));

const ItineraryList = ({ index, noRestaurantService, noHotelService, data, startLocation, endLocation, isStart, isEnd }) => {
  const classes = useStyles();

  return (
    <Accordion style={{width: '95%', margin: 'auto'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={{margin:'auto 0', display:'flex'}}>
          <EventOutlinedIcon fontSize="small"/>
          &nbsp;&nbsp;<Typography className={classes.heading}>{`${(index+1)}. ${data.date}`}</Typography>
          <Typography className={classes.subHeading}>
            {((startLocation!==undefined && startLocation!==null) || data.cities.length!==0) && (<React.Fragment>( </React.Fragment>)}
            {(startLocation!==undefined && startLocation!==null) && (<React.Fragment>{startLocation.value}</React.Fragment>)}
            {((startLocation!==undefined && startLocation!==null) && data.cities.length!==0) && (<React.Fragment>,&nbsp;</React.Fragment>)}
            {data.cities.length!==0 && (<React.Fragment>{data.cities[0].value}</React.Fragment>)}
            {data.cities.length>1 && (<React.Fragment> and other</React.Fragment>)}
            {data.cities.length>2 && (<React.Fragment>s</React.Fragment>)}
            {((startLocation!==undefined && startLocation!==null) || data.cities.length!==0) && (<React.Fragment> )</React.Fragment>)}
          </Typography>
        </div>                  
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContent}>
        <Grid container spacing={2} >
          <Grid item xs={12} style={{textAlign:'right'}}>
            <FormControlLabel
              control={
              <Switch 
                value={noRestaurantService?false:data.isLunch} 
                checked={noRestaurantService?false:data.isLunch} 
                size="small" 
                name="isLunch" />}
              label="Lunch reservation"
            />
            <FormControlLabel
              control={
              <Switch 
                value={noRestaurantService?false:data.isDinner} 
                checked={noRestaurantService?false:data.isDinner} 
                size="small" 
                color="primary" 
                name="isDinner" />}
              label="Dinner reservation"
            />
            {
              !isEnd && (
                <FormControlLabel
                  control={
                  <Switch 
                    value={noHotelService?false:data.isHotel} 
                    checked={noHotelService?false:data.isHotel} 
                    size="small" 
                    color="primary" 
                    name="isHotel" />}
                  label="Hotel reservation"
                />
              )
            }
          </Grid>
          <Grid item md={5} xs={12}>
            <ItineraryItemCities
              datas={data.cities}
              isStart={isStart}
              isEnd={isEnd}
              startLocation={startLocation}
              endLocation={endLocation}
            />
            {
              !isEnd && (
                <ItineraryItem 
                  label="Hotel"
                  datas={data.hotels}
                />
              )
            }
          </Grid>
          <Grid item md={7} xs={12}>
            <ItineraryItem 
              label="Entrance"
              datas={data.entrances}
              isShowContents={true}
            />
            <ItineraryItem 
              label="Technical Visit"
              datas={data.technicalVisits}
              isShowContents={true}
            />
            <ItineraryItemComment 
              label="Comment"
              comment={data.comment}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ItineraryList;