import React, { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,    
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import TableWrapper from 'src/components/Common/Grid/TableWrapper';
import { ArrangeCell, RequestCell, CancelCell } from 'src/components/Group/List/TourGroup/HeadCells';



const GroupList = ({ classes, tourGroups, setTourGroups, tourGroupEstimations, setTourGroupEstimations, tourGroupCancelleds, setTourGroupCancelleds, handleMethods }) => {

  const [tge, setTge] = useState(true);
  const [tgee, setTgee] = useState(true);
  const [tgce, setTgce] = useState(false);

  const lists = [
    { id: 'tour_group', title: 'Arranged List', datas: tourGroups, setDatas: setTourGroups, expanded: tge, handleExpanded: setTge, headCells: ArrangeCell.HeadCell, bodyCells: ArrangeCell.BodyCell },
    { id: 'tour_group_estimation', title: 'Requested List', datas: tourGroupEstimations, setDatas: setTourGroupEstimations, expanded: tgee, handleExpanded: setTgee,  headCells: RequestCell.HeadCell, bodyCells: RequestCell.BodyCell },
    { id: 'tour_group_cancelled', title: 'Cancelled List', datas: tourGroupCancelleds, setDatas: setTourGroupCancelleds, expanded: tgce, handleExpanded: setTgce,  headCells: CancelCell.HeadCell, bodyCells: CancelCell.BodyCell },
  ];

  return (
    <Grid>
      { 
        lists.map((item,index) => (
          <Accordion
            expanded={item.expanded}
            onChange={() => item.handleExpanded(!item.expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {item.title}
                <br/><span style={{fontSize: '0.75rem', color: 'gray'}}>If you double-click 'Group Code', you can see the details.</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableWrapper
                id={item.id}
                datas={item.datas}
                setDatas={item.setDatas}
                headCells={item.headCells}
                bodyCells={item.bodyCells}
                handleMethods={handleMethods}
                customRowPerPage={[5,10,25,50]}
              />
            </AccordionDetails>
          </Accordion>
        )) 
      }
    </Grid>
  );
}

export default GroupList;