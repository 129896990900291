import React from 'react';
import {
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { isNull, isNullStr } from "src/components/Common/Constants";

const SwitchType = ({ index, field }) => {
  const onChange = (event) => field.m(event.target.checked);

  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <div style={{lineHeight:'48px'}}>
          <FormControlLabel
            label={field.l}
            control={
              <Switch 
                id={field.id} 
                name={field.id} 
                checked={field.v} 
                onChange={onChange} 
                size="small" 
                defaultChecked={isNullStr(field.dv)}
              />}
          />
        </div>
      </Grid>    
  );
}

export default SwitchType;