import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import { debounce } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    margin: 0,
  },
  cardHeader: {
    width: '100%',
    paddingBottom: '5px',
  },
  cardContent: {
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
      "&:last-child": {
        paddingBottom: '5px',
      }
  },
  chipWrapper: {
    borderRadius: '16px',
    backgroundColor: '#e0e0e0',
    margin: theme.spacing(0.5, 2),
  },
  chipWrapperArrow: {
    padding: '0px'
  },
  chipWrapperNonArrow: {
    paddingRight: theme.spacing(1)
  }
}));

const CommentType = ({ label, type, date, comment, setComment }) => {
  const classes = useStyles();
  
  const onChangeData = (event) => {
    setComment(type, date, event.target.value)
  };

  return (
    <Grid item xs={12}>
      <Card className={classes.root}>
        <CardHeader 
          title={label}
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <textarea 
            value={comment}  
            onChange={onChangeData} 
            style={{
              resize:'none', 
              width:'100%',
              height:'100px',
              padding:'10px 16px',
              color:'#263238',
              border:'none',
              borderRadius:'4px',
              transition:'transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              boxShadow:'0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
            }} />
        </CardContent>
      </Card>
    </Grid>
  )
};

export default CommentType;
