import React from 'react';

import {
  Box,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';

const CustomTextField = ({ index, field }) => {

  const label = index + ". " + field.label;

  if (field.type==='text') {
    let attr = {item: true,lg: 3,md: 6,xs: 12,};
    return (
      <Grid {...attr }>
        <TextField
          fullWidth
          label={label}
          id={field.name}
          name={field.name}
          value={field.value}
          InputProps={{ readOnly: true, style: { textAlign: 'right' } }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>    
    );
  } else if (field.type==='switch') {
    let attr = {item: true,lg: 3,md: 4,xs: 12,};
    const checked = field.value===true && {checked:true};
    return (
      <Grid {...attr }>
        <FormControlLabel
          label={label}
          InputProps={{ readOnly: true, }}
          labelPlacement="start"
          control={
            <Switch 
              id={field.name} 
              name={field.name} 
              checked={checked}
              value={field.value}
              size="small" 
            />}
        />
      </Grid>        
    );
  } else if (field.type==='roomType') {
    let attr = {item: true,lg: 3,xs: 12,};
    return (
      <Grid {...attr }>
        <InputLabel htmlFor="component-simple" shrink>{label} ({field.value===undefined ? 0 : field.value.length} types)</InputLabel>
        <Box border={1} borderRadius={16} borderColor="grey.400" style={{minHeight: '80px'}}>
          <List dense={true}>
            {
              field.value!==undefined && field.value.map( (data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={'* ' +data.text}
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="caption" gutterBottom>
                      cnt : {data.cnt}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Grid>        
    );
  }
}

export default CustomTextField;