import { debounce } from 'lodash';

import * as AccountService from "src/services/AccountServices";
import { SimpleToastWrapper } from 'src/components/Common';

import { requestWrapper } from '../actionWrapper';

// Action define
export const INIT_ACCOUNT = '/account/initAccount';
export const SET_ACCOUNT = '/account/setAccount';

// Action creator
export function initAccount() { return { type: INIT_ACCOUNT }; }
export function setAccount(account) { return { type: SET_ACCOUNT, account, }; }
export const getAccount = ({ navigate }) => dispatch => {
  dispatch(requestWrapper(
    AccountService.getAccount(),
    (response) => {
      dispatch(setAccount(response.data.account));
    },
    null,
    navigate
  ));
}

// Action creator with redux-thunk
export const login = ({ user, targetUrl, navigate }) => (dispatch) => {

  const form = new FormData();
  form.append('username', user.email); 
  form.append('password', user.password);

  dispatch(requestWrapper(
    AccountService.login( form ),
    (response) => {
      SimpleToastWrapper({
        content: 'Login success.',
        type: 'success',
        alignItem: 'left',
      });
      
      navigate(targetUrl);
    },
    (error) => {
      const toast = {
        content: 'Login failed...',
        type: 'error',
        alignItem: 'left',
      };
    }
  ));
};
export const logout = ({ navigate }) => dispatch => {
  dispatch(requestWrapper(
    AccountService.logout(),
    (response) => {
      dispatch(initAccount());

      SimpleToastWrapper({
        content: 'Logout success...',
        type: 'success',
        alignItem: 'left',
      });

      navigate('/login');
    },
    null,
    navigate
  ));
};

// Redux-Thunk action creator
type checkLoginParams = {
  isRedirect?: boolean,
  flagWhenRedirect?: boolean, // flag 에 따른 redirect 방향 설정
  targetUrl?: string,
  history?: any,
}
export const checkLogin = ({isRedirect, flagWhenRedirect, targetUrl="/", navigate}: checkLoginParams) => dispatch => {
  dispatch(requestWrapper(
    AccountService.loginCheck(),
    (response) => {
      if( isRedirect && flagWhenRedirect ) {
        SimpleToastWrapper({
          content: 'Already logged in!',
          type: 'error',
          alignItem: 'left',
        });
        navigate(targetUrl);
      }
    },
    null,
    navigate
  ));
};

export const changeCompanyLogo = ({ formData, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    AccountService.changeCompanyLogo({ formData }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Change success!!',
          type: 'success',
          alignItem: 'left',
        });
        refreshPage();
      } else {
        SimpleToastWrapper({
          content: `Change failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};


export const changePassword = ({ newPassword, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    AccountService.changePassword({ newPassword }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Change success',
          type: 'success',
          alignItem: 'left',
        });

        if (successHandler!==undefined) {
          successHandler();
        }
      } else {
        SimpleToastWrapper({
          content: `Change failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};

export const requestNewAccount = ({ data, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    AccountService.requestNewAccount({ data }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Request success',
          type: 'success',
          alignItem: 'left',
        });
        if (successHandler!==undefined) {
          successHandler(false);
        }
      } else if (response.data.success===false) {
        SimpleToastWrapper({
          content: `Request failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      } else {
        SimpleToastWrapper({
          content: `Request failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    null
  ));
};

export const requestChangeAccount = ({ data, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    AccountService.requestChangeAccount({ data }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Request success',
          type: 'success',
          alignItem: 'left',
        });
        refreshPage();
      } else if (response.data.success===false) {
        SimpleToastWrapper({
          content: `Request failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      } else {
        SimpleToastWrapper({
          content: `Request failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    null
  ));
};
const refreshPage = debounce((groupNo, navigate) => {
  window.location.reload(false);
}, 1500);