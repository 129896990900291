import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Card,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';

import Page from 'src/components/Page';
import { SimpleToastWrapper } from 'src/components/Common';

import DetailWrapper from 'src/components/Group/Detail';
import * as GroupActions from 'src/reducers/Group/actions';

import './detail.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DetailGroupView = ({ GroupActions }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { groupNo } = useParams();

  React.useEffect(()=>{

      GroupActions.getTourGroup({ groupNo: groupNo, navigate, successHandler: (data) => {
        if (data.success === true) {
          const objects = JSON.parse(data.tourGroup.b2bObjects.params);
          setDatas(objects);

          data.tourGroup.basicInformation.isEstimation = data.tourGroup.groupType.isEstimation;

          setBasicInformation(data.tourGroup.basicInformation);
          setAttatchedList(data.tourGroup.attatchedList);
          setCommentAttatchedList(data.tourGroup.commentAttatchedList);
        } else {
          SimpleToastWrapper({
            content: `Group is not exist!!`,
            type: 'error',
            alignItem: 'left',
          });
        }
      }});
  }, []);

  const [datas,setDatas] = useState({});
  const [basicInformation,setBasicInformation] = useState({});
  const [attatchedList,setAttatchedList] = useState([]);
  const [commentAttatchedList,setCommentAttatchedList] = useState([]);

  return (
    <Page
      className={classes.root}
      title="Detail information of tour group"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Card>
              <DetailWrapper
                groupNo={basicInformation.groupNo}
                groupCode={basicInformation.groupCode}
                basicInformation={basicInformation}
                attatchedList={attatchedList}
                commentAttatchedList={commentAttatchedList}
                datas={datas}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch) => ({GroupActions: bindActionCreators(GroupActions, dispatch)})
)(DetailGroupView);