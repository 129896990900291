import React from 'react';

import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const ChangeStateBtn = ({ id, data, datas, handleMethods }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title="Change status">
        <IconButton onClick={handleClick} aria-label="History open" size="small">
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* {id==="tour_group" && (<MenuItem onClick={()=>handleMethods.handleChangeState(data.groupNo, handleMethods.navigate)}>Move to 'requested list'</MenuItem>)} */}
        {/* {id==="tour_group" && (<MenuItem onClick={()=>handleMethods.handleCancel(data.groupNo, handleMethods.navigate)}>Cancel the group</MenuItem>)} */}

        {id==="tour_group_estimation" && (<MenuItem onClick={()=>handleMethods.handleChangeState(data.groupNo, handleMethods.navigate)}>Move to 'arranged list'</MenuItem>)}
        {/* {id==="tour_group_estimation" && (<MenuItem onClick={()=>handleMethods.handleCancel(data.groupNo, handleMethods.navigate)}>Cancel the group</MenuItem>)} */}

        {/* {id==="tour_group_cancelled" && (<MenuItem onClick={()=>handleMethods.handleCancel(data.groupNo, handleMethods.navigate)}>Restore the group</MenuItem>)} */}
      </Menu>
    </div>
  );
};

export default ChangeStateBtn;