import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';

import { LoadingSpinner } from 'src/components/Common';

import * as AccountActions from 'src/reducers/Account/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'block',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const MainLayout = ({ isLoading, AccountActions }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => { 
    AccountActions.getAccount({ navigate });
  }, [AccountActions]);


  return (
    <div className={classes.root}>
      <LoadingSpinner styleClass='loginPageLoadingSpinner' visible={isLoading}/>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state, ownProps) => ({ 
    isLoading: state.isLoading 
  }),
  (dispatch) => ({
    AccountActions: bindActionCreators(AccountActions, dispatch),
  })
)(MainLayout);