import React from 'react';

import {
  Badge,
  Chip,
  Divider,
  Paper,
  makeStyles,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';

import * as GroupServices from 'src/services/GroupServices';
import './FileList.css';

const useStyles = makeStyles((theme) => ({
  root: {},
  fileDropWrapper: {
    padding: theme.spacing(1, 1.5)
  },
  fileDropzone: {
    display: 'flex',
    height: '150px',
  },
  fileListGrid: {
    paddingTop: theme.spacing(2)
  },
  fileListWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    minHeight: '100px',
    border: "1px solid rgb(189, 189, 189)", 
    borderRadius: "6px", 
    width: "95%", 
    margin: "auto", 
    padding: "10px 20px"
  },
  file: {
    margin: theme.spacing(1),
  },
  historyFileBadge: {
    left: '23px',
    top: '3px',
  },
  commentBorder: {
    border: "1px solid rgb(189, 189, 189)", 
    borderRadius: "6px", 
    width: "95%", 
    margin: "auto", 
    padding: "10px 20px"
  },
}));

const FileList = ({ files, commentFiles }) => {
  const classes = useStyles();

  const downloadFile = async (groupNo, fileUrl) => {
      const datas = await GroupServices.getFile({ groupNo: groupNo, fileUrl: fileUrl });
      window.open(datas.data);
  }

  return (
    <Paper component="ul" className={classes.fileListWrapper}>
      { files.map((file, index) => {
        let icon;
        if (
          file.fileType.toLowerCase().endsWith('png') 
          || file.fileType.toLowerCase().endsWith('img')
          || file.fileType.toLowerCase().endsWith('jpg')
          || file.fileType.toLowerCase().endsWith('jpeg')) {
          icon = <ImageIcon size="small" />
        } else {
          icon = <DescriptionIcon size="small" />;
        }

        return (
          <li key={index}>
            <Chip
              size="small"
              icon={icon}
              label={`${file.name} (${moment(file.uploadedDate).format('YYYY-MM-DD HH:mm')})`}
              className={classes.file}
              onDelete={() => downloadFile(file.groupNo, file.prefix+'/'+file.name)} 
              deleteIcon={<GetAppIcon size="small" />} 
            />
          </li>
        )
      })}
      {/* <br/>
      <Divider variant="middle" style={{width: '97%'}} /> */}
      { commentFiles.map((file, index) => {
        let icon;
        if (
          file.fileType.toLowerCase().endsWith('png') 
          || file.fileType.toLowerCase().endsWith('img')
          || file.fileType.toLowerCase().endsWith('jpg')
          || file.fileType.toLowerCase().endsWith('jpeg')) {
          icon = <ImageIcon size="small" />
        } else {
          icon = <DescriptionIcon size="small" />;
        }

        return (
          <li key={index} className="historyBadge">
            <Badge 
              className="historyBadge"
              color="secondary" 
              badgeContent='on History'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
                <Chip
                  size="small"
                  icon={icon}
                  label={`${file.name} (${moment(file.uploadedDate).format('YYYY-MM-DD HH:mm')})`}
                  className={classes.file}
                  onDelete={() => downloadFile(file.groupNo, file.prefix+'/'+file.name)} 
                  deleteIcon={<GetAppIcon size="small" />} 
                />
            </Badge>
          </li>
        )
      })}
    </Paper>
  );
};

export default FileList;