import * as Actions from './actions';

export function account(state = {}, action) {
  switch (action.type) {
    case Actions.INIT_ACCOUNT:
      return {};
    case Actions.SET_ACCOUNT:
      return { ...action.account };
    default:
      return state;
  }
}
