// @flow
import React from 'react';

import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';

import { FORMAT_DATE } from '../Constants';
import './DatePicker.css';
import 'react-dates/lib/css/_datepicker.css';

type Props = {
  onChange: Function,
}
type State = {
  createdAt: moment,
  calendarFocused: boolean,
}

class SimpleDatePickerWrapper extends React.Component<Props, State> {
  state= {
    createdAt: moment(),
    calendarFocused: false
  };

  onDateChange = (createdAt: moment) => {
    this.setState(() => ({ createdAt }));
    this.props.onChange(createdAt);
  };

  onFocusChange = ({ focused }: {focused: boolean}) => {
    this.setState(() => ({ calendarFocused: focused }))
  };

  render() {
    return (
      <div>
        <form>
          <SingleDatePicker
              date={this.state.createdAt} 
              onDateChange={this.onDateChange}
              focused={this.state.calendarFocused} 
              onFocusChange={this.onFocusChange} 
              numberOfMonths={1}
              isOutsideRange={() => false}
              displayFormat={FORMAT_DATE}
              className="form-control"
          />
       </form>
      </div>
    )
  }
}

export default SimpleDatePickerWrapper;