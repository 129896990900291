
import { debounce } from 'lodash';
import moment from 'moment';

import * as GroupService from "src/services/GroupServices";
import { SimpleToastWrapper } from 'src/components/Common';
import { FORMAT_DATE } from 'src/components/Common/Constants'; 

import { requestWrapper } from '../actionWrapper';

// Action define
export const SET_BASIC_INFORMATION = '/group/setBasicInformation';
export const SET_GROUP_COMMENTS = '/group/setGroupComments';
export const SET_GROUP_COMMENT = '/group/setGroupComment';
export const SET_HOTEL_LIST = '/group/setHotelList';
export const SET_HOTEL_LIST_RQ_FIXED = '/group/setHotelListRqFixed';

// Action creator

export const getTourGroup = ({ groupNo, successHandler, navigate }) => (dispatch, getState) => { 
  dispatch(requestWrapper(
    GroupService.getTourGroup({ groupNo }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};
export const getTourGroups = ({ startDate, endDate, groupCode, successHandler, navigate }) => (dispatch, getState) => { 
  const stDate = startDate===null ? moment(new Date()).subtract(1,'year').set({hour:0,minute:0,second:0,millisecond:0}).format(FORMAT_DATE) : moment(startDate).format(FORMAT_DATE);
  const edDate = endDate===null ? moment(new Date()).add(5,'year').set({hour:23,minute:59,second:59,millisecond:0}).format(FORMAT_DATE) : moment(endDate).format(FORMAT_DATE);

  dispatch(requestWrapper(
    GroupService.getTourGroups({ startDate: stDate, endDate: edDate, groupCode }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};
export const getTemporarySavedTourGroups = ({ successHandler, navigate }) => (dispatch, getState) => { 
  dispatch(requestWrapper(
    GroupService.getTemporarySavedTourGroups({ }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};
export const getTemporarySavedTourGroup = ({ groupTempNo, successHandler, navigate }) => (dispatch, getState) => { 
  dispatch(requestWrapper(
    GroupService.getTemporarySavedTourGroup({ groupTempNo }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};
export const deleteTemporarySavedTourGroup = ({ groupTempNo, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.deleteTemporarySavedTourGroup({ groupTempNo }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Temporary delete success',
          type: 'success',
          alignItem: 'left',
        });
        
        successHandler(response.data.temporarySavedTourGroups);

      } else {
        SimpleToastWrapper({
          content: `Temporary group delete failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};
export const saveGroupTemporary = ({ formData, setGroupTempNo, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.saveGroupTemporary({ formData }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Temporary save success',
          type: 'success',
          alignItem: 'left',
        });
        setGroupTempNo(response.data.tempGroup.groupTempNo);

        if (successHandler!==undefined) {
          successHandler(response.data.tempGroup.groupTempNo);
        }
      } else {
        SimpleToastWrapper({
          content: `Temporary save failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};
export const updateGroup = ({ formData, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.updateGroup({ formData }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Update success',
          type: 'success',
          alignItem: 'left',
        });

        moveGroupPage(response.data.groupNo, navigate);
      } else {
        SimpleToastWrapper({
          content: `Update failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};
export const createGroup = ({ formData, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.createGroup({ formData }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Create success',
          type: 'success',
          alignItem: 'left',
        });

        moveGroupPage(response.data.groupNo, navigate);
      } else {
        SimpleToastWrapper({
          content: `Create failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};
export const changeGroupState = ({ groupNo, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.changeGroupState({ groupNo }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Move success',
          type: 'success',
          alignItem: 'left',
        });
        successHandler();
      } else {
        SimpleToastWrapper({
          content: `Change failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};
export const changeFix = ({ groupNo, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.changeFix({ groupNo }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Change success',
          type: 'success',
          alignItem: 'left',
        });
        successHandler();
      } else {
        SimpleToastWrapper({
          content: `Change failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};
export const changeCancel = ({ groupNo, navigate, successHandler }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.changeCancel({ groupNo }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Change success',
          type: 'success',
          alignItem: 'left',
        });
        successHandler();
      } else {
        SimpleToastWrapper({
          content: `Change failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};

export function setBasicInformation( basicInformation ) { return { type: SET_BASIC_INFORMATION, basicInformation } };
export function setGroupComments( comments ) { return { type: SET_GROUP_COMMENTS, comments } };
export function setGroupComment( comment ) { return { type: SET_GROUP_COMMENT, comment } };

export const getGroupComments = ({ groupNo, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.getGroupComments({ groupNo }),
    (response) => {
      dispatch(setBasicInformation(response.data.basicInformation));
      dispatch(setGroupComments(response.data.comments));
    },
    null,
    navigate
  ));
};
export const getGroupComment = ({ groupNo, commentNo, successHandler, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.getGroupComment({ groupNo, commentNo }),
    (response) => {
      dispatch(setBasicInformation(response.data.basicInformation));
      dispatch(setGroupComment(response.data.comment));

      if (successHandler !== undefined) {
        successHandler(response.data);
      }
    },
    null,
    navigate
  ));
};
export const createComment = ({ formData, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.createComment({ formData }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Send success!!',
          type: 'success',
          alignItem: 'left',
        });

        closeWindow();
      } else {
        SimpleToastWrapper({
          content: `Send failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};

const moveGroupPage = debounce((groupNo, navigate) => {
  navigate(`/group/${groupNo}`);
}, 1500);
const moveDashboard = debounce((navigate) => {
  navigate('/group/list');
}, 1500);
const closeWindow = debounce(() => {
  window.opener.location.reload();
  window.close();
}, 3000);

export function setHotelList( hotelList ) { return { type: SET_HOTEL_LIST, hotelList } };
export function setHotelListRQFixedSuccess( data ) { return { type: SET_HOTEL_LIST_RQ_FIXED, hotelListNo: data.hotelListNo, rqFixed: data.rqFixed } };
export const getHotelList = ({ startDate, endDate, groupCode, navigate }) => (dispatch, getState) => { 
  const stDate = startDate===null ? moment(new Date()).subtract(1,'year').set({hour:0,minute:0,second:0,millisecond:0}).format(FORMAT_DATE) : moment(startDate).format(FORMAT_DATE);
  const edDate = endDate===null ? moment(new Date()).add(5,'year').set({hour:23,minute:59,second:59,millisecond:0}).format(FORMAT_DATE) : moment(endDate).format(FORMAT_DATE);

  dispatch(requestWrapper(
    GroupService.getGroupHotels({ startDate: stDate, endDate: edDate, groupCode }),
    (response) => ( 
      dispatch(
        setHotelList( response.data.groupHotels )
        ) 
      ),
    null,
    navigate
  ));
};
export const setGroupHotelRqForFixed = ({ groupNo, hotelListNo, rqFixed, accommodationDate, hotelName, navigate }) => (dispatch, getState) => {
  dispatch(requestWrapper(
    GroupService.setGroupHotelRqForFixed({ groupNo, hotelListNo, rqFixed, accommodationDate, hotelName }),
    (response) => {
      if (response.data.success===true) {
        SimpleToastWrapper({
          content: 'Request success',
          type: 'success',
          alignItem: 'left',
        });
        dispatch(setHotelListRQFixedSuccess(response.data));
      } else {
        SimpleToastWrapper({
          content: `Request failed!! (msg:${response.data.message})`,
          type: 'error',
          alignItem: 'left',
        });
      }
    },
    null,
    navigate
  ));
};