import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { loadingBarReducer } from 'react-redux-loading-bar';

import { isLoading } from './Common/reducer';
import { account } from './Account/reducer';
import { groupBasicInformation, groupComments, groupComment, hotelList } from './Group/reducer';

/* eslint-disable no-underscore-dangle */
export default function configureStore() {
  return createStore(
    combineReducers({
      loadingBar: loadingBarReducer,
      isLoading,

      account,

      // reducer for group
      groupBasicInformation,
      groupComments,
      groupComment,
      hotelList,
    }),
    applyMiddleware(
      thunkMiddleware,
      logger,
    ),
  );
}
