import React from 'react';
import {
  Box,
  Button,
  Grid,
} from '@material-ui/core';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import { ADMIN_ADDR } from 'src/components/Common/Constants';

const ButtonBox = ({ groupNo, groupCode, basicInformation }) => {
  const openHistory = () => {
    window.open(`/popup/group/${groupNo}/comments`, `History of ${groupCode}`, `width=1000, height=800,location=no,status=no,scrollbars=yes`)
  };
  const openEditPage = () => {
    window.open(`/group/edit?groupNo=${groupNo}&isEdit=1`);
  }
  const openDuplicatePage = () => {
    window.open(`/group/edit?groupNo=${groupNo}`);
  }
  const openQuotation = () => {
    let division = 'eng';
    if (basicInformation.nationality==='31-1') division = 'kor';
    if (basicInformation.nationality==='31-2') division = 'jpn';

    window.open(`${ADMIN_ADDR}/tourGroup/previewEstimationDocument/?groupNo=${groupNo}&language=${division}`, '_blank', `width=815, height=1000,location=no,status=no,scrollbars=yes`)
  }
  const openPreview = () => {
    window.open(`${ADMIN_ADDR}/b2b/preview/requestPlan/${groupNo}`, '_blank', `width=815, height=1000,location=no,status=no,scrollbars=yes`)
  }
  const openSemiPlan = () => {
    window.open(`${ADMIN_ADDR}/b2b/preview/finalPlan/${groupNo}`, '_blank', `width=815, height=1000,location=no,status=no,scrollbars=yes`)
  }
  const downloadFinalPlan = () => {
    window.open(`${ADMIN_ADDR}/planPreview2/export/word/${groupNo}`)
  }
  const openHotelList = () => {
    window.open(`${ADMIN_ADDR}/b2b/preview/hotelList/${groupNo}`, '_blank', `width=815, height=1000,location=no,status=no,scrollbars=yes`)
  }
  const downloadQuotation = () => {
    let division = 'eng';
    if (basicInformation.nationality==='31-1') division = 'kor';
    if (basicInformation.nationality==='31-2') division = 'jpn';

    window.open(`${ADMIN_ADDR}/tourGroup/estimationDocument/export/word/${groupNo}/${division}`);
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      p={2}
    >
      <Grid container justify="flex-end" spacing={1}>
        <Grid item sm='auto' xs={12}>
          <Button
            color="primary"
            variant="contained"
            style={{marginRight: '10px', backgroundColor: '#5f2135'}}
            fullWidth
            onClick={openQuotation}
            startIcon={<OpenInBrowserIcon fontSize="inherit" />}
          >
            Quotation
          </Button>
        </Grid>
        <Grid item sm='auto' xs={12}>
          <Button
            color="primary"
            variant="contained"
            style={{marginRight: '10px', backgroundColor: '#5f2135'}}
            fullWidth
            onClick={openPreview}
            startIcon={<OpenInBrowserIcon fontSize="inherit" />}
          >
            Preview
          </Button>
        </Grid>
          {
            (basicInformation.isEstimation===1 && basicInformation.sentFinal===true) && (
              <Grid item sm='auto' xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginRight: '10px', backgroundColor: '#5f2135'}}
                  fullWidth
                  onClick={downloadQuotation}
                  startIcon={<CloudDownloadIcon fontSize="inherit" />}
                >
                  Download Quotation
                </Button>
              </Grid>
            )
          }
          {
            (basicInformation.isEstimation===0 && basicInformation.sentPreview===true) && (
              <Grid item sm='auto' xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginRight: '10px', backgroundColor: '#5f2135'}}
                  fullWidth
                  onClick={openSemiPlan}
                  startIcon={<OpenInBrowserIcon fontSize="inherit" />}
                >
                  Semi Plan
                </Button>
              </Grid>
            )
          }
          {
            (basicInformation.isEstimation===0 && basicInformation.sentFinal===true) && (
              <Grid item sm='auto' xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginRight: '10px', backgroundColor: '#5f2135'}}
                  fullWidth
                  onClick={downloadFinalPlan}
                  startIcon={<CloudDownloadIcon fontSize="inherit" />}
                >
                  Final plan
                </Button>
              </Grid>
            )
          }
          {
            (basicInformation.isEstimation===0 && basicInformation.fixGroup===1) && (
              <Grid item sm='auto' xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{marginRight: '10px', backgroundColor: '#5f2135'}}
                  fullWidth
                  onClick={openHotelList}
                  startIcon={<OpenInBrowserIcon fontSize="inherit" />}
                >
                  Hotel List
                </Button>
              </Grid>
            )
          }
        <Grid item sm='auto' xs={12}>
          <Button
            variant="contained"
            style={{marginRight: '10px'}}
            fullWidth
            onClick={openHistory}
          >
            History
          </Button>
        </Grid>
        <Grid item sm='auto' xs={12}>
          <Button
            color="primary"
            variant="contained"
            style={{marginRight: '10px'}}
            fullWidth
            onClick={openEditPage}
          >
            Edit
          </Button>
        </Grid>
        <Grid item sm='auto' xs={12}>
          <Button
            color="primary"
            variant="contained"
            style={{marginRight: '10px'}}
            fullWidth
            onClick={openDuplicatePage}
          >
            Duplicate
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
};

export default ButtonBox;