import CityType from './CityType';
import CommentType from './CommentType';
import DefaultType from './DefaultType';

function SimplePlanItem(props) {
  return props.children;
}

SimplePlanItem.displayName = "SimplePlanItem";

SimplePlanItem.City = CityType;
SimplePlanItem.Comment = CommentType;
SimplePlanItem.Default = DefaultType;

export default SimplePlanItem;

export {
  CityType,
  CommentType,
  DefaultType,
};