import React from 'react';
import {
  Grid,
  InputLabel,
} from '@material-ui/core';

import { isNull } from "src/components/Common/Constants";

const WrapperType = ({ index, field, children }) => {
  return (
      <Grid 
        container
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
        alignItems="flex-start"
      >
        <Grid container spacing={2}>
          { field.l!==undefined && (<InputLabel htmlFor="component-simple" shrink>{field.l}</InputLabel>)}
          {children}
        </Grid>
      </Grid>    
  );
}

export default WrapperType;