import moment from 'moment';

import * as CommonService from "src/services/CommonServices";
import { FORMAT_DATE } from 'src/components/Common/Constants'; 

import { requestWrapper } from '../actionWrapper';

// Action define
export const FETCH_LOADING_REQUEST = '/common/fetchLoadingStart';
export const FETCH_LOADING_FINISH = '/common/fetchLoadingFinish';
export const FETCH_LOADING_RESET = '/common/fetchLoadingReset';

// Action creator
export function fetchLoadingStart() { return { type: FETCH_LOADING_REQUEST }; }
export function fetchLoadingFinish() { return { type: FETCH_LOADING_FINISH }; }
export function fetchLoadingReset() { return { type: FETCH_LOADING_RESET }; }

export const getNotices = ({ startDate, endDate, successHandler, navigate }) => (dispatch, getState) => { 
  const stDate = startDate===null ? moment(new Date()).subtract(1,'year').set({hour:0,minute:0,second:0,millisecond:0}).format(FORMAT_DATE) : moment(startDate).format(FORMAT_DATE);
  const edDate = endDate===null ? moment(new Date()).add(5,'year').set({hour:23,minute:59,second:59,millisecond:0}).format(FORMAT_DATE) : moment(endDate).format(FORMAT_DATE);

  dispatch(requestWrapper(
    CommonService.getNotices({ startDate: stDate, endDate: edDate }),
    (response) => {
      successHandler(response.data);
    },
    null,
    navigate
  ));
};