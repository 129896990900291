import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { SimpleToastWrapper } from 'src/components/Common';
import { FORMAT_DATE } from 'src/components/Common/Constants'; 

import Page from 'src/components/Page';
import Toolbar from 'src/components/Group/List/Toolbar';

import GroupList from './GroupList';
import TemporarySavedList from './TemporarySavedList';
import EventList from './EventList';

import * as CommonActions from 'src/reducers/Common/actions';
import * as GroupActions from 'src/reducers/Group/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const ListGroupView = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [groupCode, setGroupCode] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [tourGroups, setTourGroups] = useState([]);
  const [tourGroupEstimations, setTourGroupEstimations] = useState([]);
  const [tourGroupCancelleds, setTourGroupCancelleds] = useState([]);

  const [temporarySavedTourGroups, setTemporarySavedTourGroups] = useState([]);
  const [notices, setNotices] = useState([]);

  React.useEffect(() => { 

      props.GroupActions.getTourGroups({ startDate, endDate, groupCode, navigate, successHandler: (data) => {
        setTourGroups(data.tour_group);
        setTourGroupEstimations(data.tour_group_estimation);
        setTourGroupCancelleds(data.tour_group_cancelled);
      } }); 

      props.GroupActions.getTemporarySavedTourGroups({ navigate, successHandler: (data) => {
        setTemporarySavedTourGroups(data.temporarySavedTourGroups);
      } }); 

      props.CommonActions.getNotices({ startDate, endDate, navigate, successHandler: (data) => {
        setNotices(data.notices);
      } }); 
    }, []);
    
  const onClickSearch = () => {
    // Toolbar limitation.
    if ( (startDate !== null && endDate === null) || (startDate === null && endDate !== null) ) {
      SimpleToastWrapper({
        content: 'You can search less than 1 year.',
        type: 'error',
        alignItem: 'left',
      });

      return false;
    }
    if (!(startDate === null && endDate === null) && endDate.diff(startDate, 'days') > 366) {
      SimpleToastWrapper({
        content: 'You can search less than 1 year.',
        type: 'error',
        alignItem: 'left',
      });

      return false;
    }

    props.GroupActions.getTourGroups({ startDate, endDate, groupCode, navigate, successHandler: (data) => {
      setTourGroups(data.tour_group);
      setTourGroupEstimations(data.tour_group_estimation);
      setTourGroupCancelleds(data.tour_group_cancelled);
    } }); 

    props.CommonActions.getNotices({ startDate, endDate, navigate, successHandler: (data) => {
      setNotices(data.notices);
    } }); 
  };

  const handleChangeState = (groupNo, navigate) => {
    props.GroupActions.changeGroupState({groupNo, navigate, successHandler: onClickSearch});
  }
  const handleFix = (groupNo, navigate) => {
    props.GroupActions.changeFix({groupNo, navigate, successHandler: onClickSearch});
  }
  const handleCancel = (groupNo, navigate) => {
    props.GroupActions.changeCancel({groupNo, navigate, successHandler: onClickSearch});
  }


  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth="false">
        <Grid
          container
          spacing={1}
        >
          <Grid 
            item
            xs={12}
          >
            <Toolbar 
              className="toobarCustom"
              startDate={startDate===null ? null : moment(startDate, FORMAT_DATE)}
              setStartDate={setStartDate}

              endDate={endDate===null ? null : moment(endDate, FORMAT_DATE)}
              setEndDate={setEndDate}

              groupCode={groupCode}
              setGroupCode={setGroupCode}

              onClickSearch={onClickSearch}
            />
          </Grid>
          <Grid
            item
            md={8}
            xs={12}
          >
            <GroupList
              classes={classes}
              tourGroups={tourGroups}
              setTourGroups={setTourGroups}
              tourGroupEstimations={tourGroupEstimations}
              setTourGroupEstimations={setTourGroupEstimations}
              tourGroupCancelleds={tourGroupCancelleds}
              setTourGroupCancelleds={setTourGroupCancelleds}
              handleMethods={{
                handleChangeState,
                handleFix,
                handleCancel,
                navigate,
              }}
             />
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
          >
            <TemporarySavedList 
              classes={classes}
              temporarySavedTourGroups={temporarySavedTourGroups}
              navigate
              handleMethods={{
                handleSetTemporarySavedGroup: setTemporarySavedTourGroups
              }}
            />
          </Grid>
          <Grid
            item
            // md={4}
            xs={12}
          >
            <EventList 
              classes={classes}
              notices={notices}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch) => ({
    CommonActions: bindActionCreators(CommonActions, dispatch),
    GroupActions: bindActionCreators(GroupActions, dispatch),
    })
)(ListGroupView);
