import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { debounce } from 'lodash';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import * as GroupServices from 'src/services/GroupServices';

import './DefaultType.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    margin: 0,
    marginBottom: '15px',
  },
  cardHeader: {
    width: '100%',
    paddingBottom: '5px',
  },
  cardContent: {
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
      "&:last-child": {
        paddingBottom: '5px',
      }
  },
  chipWrapper: {
    borderRadius: '16px',
    backgroundColor: '#e0e0e0',
    margin: theme.spacing(0.5, 2),
  },
  chipWrapperArrow: {
    padding: '0px'
  },
  chipWrapperNonArrow: {
    paddingRight: theme.spacing(1)
  },
  hotelLocationTitle: {
    textAlign: 'right',
    marginBottom: '10px',
  }
}));

const DefaultType = ({ label, type, date, datas, setDatas, endCity, cityIds, isMovable, isDisabled }) => {
  const classes = useStyles();
  const [, forceUpdate] = React.useState(0);
  
  const [reRender, setReRender] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const handleMoveOrder = (index) => {
    let copied = [...datas];
    let spliced = copied.splice(index, 1);
    copied.splice(index+1, 0, spliced[0]);
    setDatas( type, date, copied );
    forceUpdate(n => !n);
  }
  const handleDelete = (index) => () => {
    setDatas( type, date, datas.filter((chip, i) => i !== index));
    forceUpdate(n => !n);
  };

  const handleChange = (value, reason) => {
    if ( reason === "select-option" ) {
      setDatas( type, date, [...datas, { key: value.key, label:value.value, cityId: value.cityId }]);
      setReRender(!reRender);
    }
  }

  const handleInputChange = debounce(async (value, reason) => {
    if (value.length >= 2 && reason === 'input') {
      setLoading(true);
      const lcValue = value.toLowerCase();
      let result = [];
      if (type === 'entrances') {
        const datas = await GroupServices.getEntrances({ cities: cityIds, entranceName: lcValue});
        result=datas.data.entrances;
      } else if (type === 'technicalVisits') {
        const datas = await GroupServices.getTechnicalVisits({ cities: cityIds, technicalVisitName: lcValue});
        result=datas.data.technicalVisits;
      } else if (type === 'hotels') {
        const datas = await GroupServices.getHotels({ city: endCity, hotelName: lcValue});
        result=datas.data.hotels;
      }
      setOptions(result);
      setLoading(false);
    }
  }, 500);

  const filterOptions = (options, { inputValue }) => {return options;};

  return (
    <Grid item xs={12} className="ItineraryItemWrapper">
      <Card className={classes.root}>
        <CardHeader 
          title={label}
          className={classes.cardHeader}
          action={
            <Autocomplete
              id={date+"_"+type}
              style={{ width: 250 }}
              groupBy={(option) => option.groupKey}
              getOptionLabel={(option) => option.label}
              options={options}
              loading={loading}
              clearOnBlur={false}
              key={reRender}
              filterOptions={filterOptions}
              disabled={isDisabled===undefined?false:isDisabled}
              onChange={(event, value, reason) => handleChange(value, reason)}
              onInputChange={(event, value, reason) => handleInputChange(value, reason)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Input " + label + " Name."}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          }
        />
        <CardContent className={classes.cardContent}>
          {endCity!==null&&endCity!==undefined && (
            <Typography variant="h6" component="h1" className={classes.hotelLocationTitle}>(@ {endCity.label})</Typography>
          )}
          <Paper component="ul" className={classes.root}>
            {datas.map((data, index) => {
              return (
                <li key={index}>
                  <div className={classes.chipWrapper}>
                      {
                        (isMovable===true && index!==0) ? (
                          <IconButton aria-label="movePositionPrev" size="small" className={classes.chipWrapperArrow} onClick={()=>handleMoveOrder(index-1)}>
                            <KeyboardArrowLeftIcon size="small"/>
                          </IconButton>
                        ) : (
                          <span className={classes.chipWrapperNonArrow}>&nbsp;</span>
                        )
                      }
                      <Chip
                        label={(index+1) + ". " +data.label}
                        onDelete={handleDelete(index)}
                        className={classes.chip}
                        size="small" 
                      />
                      {
                        (isMovable===true && index!==datas.length-1) ? (
                          <IconButton aria-label="movePositionNext" size="small" className={classes.chipWrapperArrow} onClick={()=>handleMoveOrder(index)}>
                            <KeyboardArrowRightIcon size="small"/>
                          </IconButton>
                        ) : (
                          <span className={classes.chipWrapperNonArrow}>&nbsp;</span>
                        )
                      }
                  </div>
                </li>
              );
            })}
          </Paper>
        </CardContent>
      </Card>
    </Grid>
  )
};

export default DefaultType;