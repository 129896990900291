import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Collapse,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Grid,
  Paper,
  IconButton,
} from '@material-ui/core';

import FindReplaceIcon from '@material-ui/icons/FindReplace';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableBody(props) {
  const { classes, id, bodyCells, rowKey, data, datas, setDatas, handleMethods, isNumbering, numbering, contentFormat } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        key={rowKey}
        size="small"
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {isNumbering !== undefined && isNumbering === true && (
          <TableCell align='center'>{numbering}</TableCell>
        )}
        { bodyCells.map((bodyCell, index) => (
          // <span>{bodyCell}</span>
          <TableCell 
            key={index}
            align={bodyCell.align!==undefined ? bodyCell.align : 'center'}
            className={bodyCell.className !== undefined ? classes[bodyCell.className] : ''} 
          >
            { !bodyCell.isOnlyChildren && (<span>{data[bodyCell.key]}</span>) }
            { bodyCell.children!==undefined && (<React.Fragment>{ bodyCell.children({ id, data, datas, setDatas, handleMethods })}</React.Fragment>) }
          </TableCell>
        )) }
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={ (bodyCells.length+1+(isNumbering===true?1:0))}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={3}>
              { contentFormat(data) }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EnhancedTableHead(props) {
  const { classes, headCells, order, orderBy, onRequestSort, isNumbering } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCellIndex} />
        {isNumbering !== undefined && isNumbering === true && (
          <TableCell
            className={classes.tableCellIndex} 
            align='center'
            sortDirection={false}
          >
            No.
          </TableCell>
        )}
        { headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align!==undefined ? headCell.align : 'center'}
            width={headCell.width!==undefined ? headCell.width : ''}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.className !== undefined ? classes[headCell.className] : ''} 
          >
          {
            headCell.isSortable === true ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
                <span style={{fontSize: '12px'}}>{headCell.label}</span>
            )
          }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.array.isRequired,
  bodyCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  toolbar: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(1)
  },
  title: {
    paddingLeft: "20px"
  },
  tableCellIndex: {
    width: '30px'
  },
  width100: { width: '100px' },
  width160: { width: '160px' },
  width180: { width: '180px' },
  margin: theme.spacing(1),
}));

export default function ExpandTableWrapper({ id, isNumbering, title, expandButtonActions, datas, setDatas, handleMethods, headCells, bodyCells, customRowPerPage, contentFormat }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState((customRowPerPage!==undefined) ? customRowPerPage[0] : 10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const shortageRows = ((customRowPerPage!==undefined) ? customRowPerPage[0] : 10) - Math.min(rowsPerPage, datas.length - page * rowsPerPage);
  const emptyRows = (shortageRows < 0) ? 0 : shortageRows;
  const sortDatas = stableSort(datas, getComparator(order, orderBy));

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar
          className={clsx(classes.toolbar)}
        >
          <Grid container>
            <Grid item xs>
              <Typography
                className={classes.title}
                variant="h3"
                id="tableTitle"
                component="span"
              >
                {title===undefined ? '' : (title)}
              </Typography>
              <Typography
                className={classes.title}
                variant="h6"
                id="tableTitle"
                component="span"
              >
                {title===undefined ? '' : (' (count : '+datas.length+')')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {
                expandButtonActions !== undefined && (
                  <div style={{textAlign:'right'}}>
                    { expandButtonActions.map((buttonAction, index) => (
                      <Button key={index} onClick={() => (buttonAction.handleMethod())} size="small" color="primary" variant="contained">
                        {buttonAction.label}
                      </Button>
                    ))}
                  </div>
                )
              }
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="table"
          >
            <EnhancedTableHead
              classes={classes}
              isNumbering={isNumbering}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={datas.length}
              headCells={headCells}
            />
            <TableBody>
              {sortDatas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => {
                  const labelId = `enhanced-table${index}`;

                  return (
                    <EnhancedTableBody
                      isNumbering={isNumbering}
                      numbering={((page * rowsPerPage) + index + 1)}
                      id={id}
                      classes={classes}
                      bodyCells={bodyCells}
                      rowKey={labelId}
                      data={data} 
                      datas={datas}
                      setDatas={setDatas}
                      handleMethods={handleMethods}
                      contentFormat={contentFormat}
                    />
                  );
                })}
              { sortDatas.length !== 0 && emptyRows > 0 && (
                <TableRow style={{ height: 43 * emptyRows }}>
                  <TableCell colSpan={headCells.length+1+(isNumbering===true?1:0)} />
                </TableRow>
              )}
              {  sortDatas.length === 0 && (
                  <TableRow style={{ height: 43 * emptyRows }}>
                    <TableCell colSpan={headCells.length+1+(isNumbering===true?1:0)}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        justifyContent="center"
                      >
                        <Container maxWidth="md" style={{marginTop:'70px',marginBottom:'70px'}}>
                          <Typography
                            align="center"
                            color="textSecondary"
                            variant="h1"
                          >
                          <FindReplaceIcon />
                            &nbsp;&nbsp;No result found
                          </Typography>
                        </Container>
                      </Box>
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={(customRowPerPage!==undefined) ? customRowPerPage : [10, 25, 50, 100]}
          component="div"
          count={datas.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
