import React, { useState } from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';

import { isNull } from "src/components/Common/Constants";

import * as CommonServices from 'src/services/CommonServices';

const SelectType = ({ index, field }) => {
  const [datas,setDatas] = useState([]);

  // Set datas
  React.useEffect(() => {
    if (field.opts.t==="codes") {
      fetchMethodForCode(field.opts.groupId)
    } else if (field.opts.t==="operators") {
      fetchMethodForOperators();
    }
  }, [field]);
  const fetchMethodForOperators = async () => {
    const datas = await CommonServices.getOperators();
    setDatas(datas.data);
  }
  const fetchMethodForCode = async (groupId) => {
    const datas = await CommonServices.getCodes({ groupId });
    setDatas(datas.data.cdMstTb.cdDtlTbs);
  }

  if (field.opts.key===undefined)   field.opts.key = "cdDtlCd";
  if (field.opts.value===undefined) field.opts.value = "name";

  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <TextField
          select
          fullWidth
          label={field.l}
          id={field.id}
          name={field.id}
          required={isNull(field.ir)}
          onChange={isNull(field.m)}
          defaultValue={isNull(field.opts.dv)}
          disabled={isNull(field.isDisable)}
          SelectProps={{ native: true }}
          InputLabelProps={{ shrink: true, }}
        >
        {
          ( field.opts.iv!==undefined && ( <option key={field.opts.iv.key} value={field.opts.iv.key}>{field.opts.iv.value}</option> ))
        }
        {
          ( 
            datas.map((option,index) => (
              <option
                key={option[field.opts.key]}
                value={option[field.opts.key]}
                selected={field.opts.dv===option[field.opts.key]}
              >
                {(field.opts.pv!==undefined)
                  ? ((option[field.opts.pv]!==null && option[field.opts.pv]!=='') ? option[field.opts.pv] : option[field.opts.value]) 
                  : option[field.opts.value]}
              </option>
            ))
          )
        }
        </TextField>
      </Grid>    
  );
}

export default SelectType;