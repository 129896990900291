import React from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';

import { isNull, isNullStr } from "src/components/Common/Constants";

const TextType = ({ index, field }) => {
  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <TextField
          fullWidth
          label={field.l}
          id={field.id}
          name={field.id}
          required={isNull(field.ir)}
          defaultValue={isNullStr(field.dv)}
          disabled={isNull(field.d)}
          InputLabelProps={{ shrink: true, style: { textAlign: 'center' }}}
        />
      </Grid>    
  );
}

export default TextType;