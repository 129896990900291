import React from 'react';
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  Paper,

  makeStyles,
} from '@material-ui/core';
import {
  Autocomplete,

  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
  TimelineItem,
} from '@material-ui/lab';
import HotelIcon from '@material-ui/icons/Hotel';
import PinDropIcon from '@material-ui/icons/PinDrop';
import AddIcon from '@material-ui/icons/Add';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { debounce } from 'lodash';

import * as GroupServices from 'src/services/GroupServices';

import './CityType.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    margin: 0,
    marginBottom: '15px',
    boxShadow: '0 0 0 1px rgba(163,63,68,0.35), 0 1px 2px 0 rgba(163,63,68,0.25)',
  },
  cardHeader: {
    width: '100%',
    paddingBottom: '5px',
  },
  cardContent: {
    width: '100%',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '5px',
    paddingBottom: '5px',
      "&:last-child": {
        paddingBottom: '5px',
      }
  },
  paper: {
    padding: '6px 16px',
  },
  paperAutocomplete: {
    padding: '6px 6px',
  },
  addIcon: {
    backgroundColor: '#5f2135',
  },
  connector: {
    backgroundColor: '#3f51b5',
  },
  cityButton: {
    width: '24px',
    minWidth: '24px',
  }
}));

const CityType = ({ label, date, datas, setDatas, isStart, isEnd, startLocation, endLocation, isDisabled }) => {
  const classes = useStyles();

  const [reRender, setReRender] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const onChangeOrder = (index) => {
    let copied = [...datas];
    let spliced = copied.splice(index, 1);
    copied.splice(index+1, 0, spliced[0]);
    setDatas( "cities", date, copied );
  }
  const onDelete = (i) => {
    setDatas( "cities", date, datas.filter((chip,index) => index!== i));
  };

  const handleChange = (value, reason) => {
    if ( reason === "select-option" ) {
      setDatas( "cities", date, [...datas, { key: value.key, label:value.label, value:value.value, isAirport:0 }]);
      setReRender(!reRender);
    }
  }

  const handleInputChange = debounce(async (value, reason) => {
    if (value.length >= 2 && reason === 'input') {
      setLoading(true);
      const lcValue = value.toLowerCase();
      let result = [];
      
      const datas = await GroupServices.getCities({cityName: lcValue});
      result=datas.data.cities;

      setOptions(result);
      setLoading(false);
    }
  }, 500);

  const filterOptions = (options, { inputValue }) => {return options;};

  return (
      <Card className={classes.root}>
        <CardHeader 
          title="Route"
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <Timeline className="cityRouteTimeline">
          {
            (startLocation!==undefined && startLocation!==null) && (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    {(isStart) ? (<FlightLandIcon />) : (<HotelIcon />)}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">{startLocation.label}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          }
            {datas.map((data, index) => (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="primary" >
                    <PinDropIcon />
                  </TimelineDot>
                  <TimelineConnector className={classes.connector} />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1" style={{float:'left',paddingTop:'5px'}}>{data.label}</Typography>
                    <ButtonGroup
                      color="primary"
                      style={{textAlign:'right', display:'block'}}
                    >
                      <IconButton aria-label="movePositionPrev" size="small" className={classes.cityButton} disabled={index!==0?false:true} onClick={(event)=>{onChangeOrder(index-1)}}>
                        <ArrowDropUpIcon size="small"/>
                      </IconButton>
                      <IconButton aria-label="movePositionPrev" size="small" className={classes.cityButton} onClick={(event)=>{onDelete(index)}}>
                        <CloseIcon size="small"/>
                      </IconButton>
                      <IconButton aria-label="movePositionPrev" size="small" className={classes.cityButton} disabled={index !== datas.length-1?false:true} onClick={(event)=>{onChangeOrder(index)}}>
                        <ArrowDropDownIcon size="small"/>
                      </IconButton>
                    </ButtonGroup>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot className={classes.addIcon}>
                  <AddIcon />
                </TimelineDot>
                {
                  (endLocation!==null && isEnd===true) && (
                    <TimelineConnector />
                  )
                }
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paperAutocomplete}>
                  <Autocomplete
                    id={date+"_cities"}
                    style={{ width: '100%' }}
                    groupBy={(option) => option.groupKey}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    loading={loading}
                    clearOnBlur={false}
                    key={reRender}
                    filterOptions={filterOptions}
                    disabled={isDisabled===undefined?false:isDisabled}
                    onChange={(event, value, reason) => handleChange(value, reason)}
                    onInputChange={(event, value, reason) => handleInputChange(value, reason)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Input " + label + " Name."}
                        size="small"
                        InputLabelProps={{ shrink: true, }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Paper>
              </TimelineContent>
            </TimelineItem>
          {
            (endLocation!==null && isEnd===true) && (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <FlightTakeoffIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">{endLocation.label}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          }
          </Timeline>
        </CardContent>
      </Card>    
  );
}

export default CityType;