import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import moment from 'moment';

import { DatePicker } from 'src/components/Common';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    height: '38px'
  },
  button: {
    float: 'right',
    height: '38px'
  }
}));

const Toolbar = ({ className, startDate, endDate, groupCode, setStartDate, setEndDate, setGroupCode, onClickSearch, ...rest }) => {
  const classes = useStyles();

  const onChangeDate = ({startDate, endDate}: {startDate:moment, endDate:moment})=>{
      setStartDate(startDate);
      setEndDate(endDate);
  }

  const onChangeData = (e: SyntheticEvent<HTMLInputElement>)=>{
    let target = e.target;
    if (target instanceof HTMLInputElement) {
      setGroupCode(target.value);
    }
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={classes.textField}>
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Group Code"
                  variant="outlined"
                  onChange={onChangeData}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <DatePicker.RangeDatePickerWrapper
                  className="form-control"
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<SearchIcon />}
                  onClick={onClickSearch}
                  fullWidth
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  groupCode: PropTypes.string,
  setGroupCode: PropTypes.func,
};

export default Toolbar;
