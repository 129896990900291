import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Badge,
  Box,
  Container,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import Page from 'src/components/Page';
import Toolbar from 'src/components/Supplier/Theme/Toolbar';
import TableWrapper from 'src/components/Common/Grid/TableWrapper';

import * as SupplierActions from 'src/reducers/Supplier/actions';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ThemeList = (props) => {
  const classes = useStyles();

  const [themes, setThemes] = useState([]);
  const navigate = useNavigate();
  
  const { SupplierActions } = props;

  const handleSearch = () => {
    const name = document.getElementById('name').value.toLowerCase();
    const country = document.getElementById('country').value;
    const city = document.getElementById('city').value.split(",")[0];
    const cate1 = document.getElementById('cate1').value;
    const cate2 = document.getElementById('cate2').value;
    const cate3 = document.getElementById('cate3').value;

    SupplierActions.getThemes({name, country, city, cate1, cate2, cate3, navigate, successHandler: (data) => {
      setThemes(data.themes);
    }});
  };
  const handleAdd = () => {
    navigate('/supplier/theme/add');
  }

  const headCells = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'countryName', label: 'Country', width: '180px', align: 'right' },
    { id: 'cityName', label: 'City/Location', width: '180px', align: 'right' },
    { id: 'cate1', label: 'Category 1', width: '160px', align: 'right' },
    { id: 'cate2', label: 'Category 2', width: '160px', align: 'right' },
    { id: 'cate3', label: 'Category 3', width: '160px', align: 'right' },
    { id: 'tags', label: 'Tags', width: '160px', align: 'right' },
    { id: 'contents', label: 'Contents', width: '60px' },
    { id: 'website', label: 'Website', width: '60px' },
  ];
  const bodyCells = [
    { key: 'name', align: 'left' },
    { key: 'countryName', align: 'right' },
    { key: 'cityName', align: 'right', children: (({ data })=>{
      return (
        (data.place!==null && data.cityName!==data.place.trim() && data.place.trim()!=='') && (<span><br/>( {data.place} )</span>)
      )
    }) },
    { key: 'cate1', align: 'right' },
    { key: 'cate2', align: 'right' },
    { key: 'cate3', align: 'right' },
    { key: 'tagString', align: 'right' },
    { key: 'contents', isOnlyChildren: true, children: (({ data })=>{
      return (
        data.contentsNum != 0 && (
          <Tooltip title="View contents">
          <Badge badgeContent={data.newHistory} color="error">
            <IconButton onClick={() => {window.open(`/popup/supplier/contents/${data.supplierNo}`, `Contents of ${data.name}`, `width=750, height=1200,location=no,status=no,scrollbars=yes`)}} aria-label="Contents open" size="small">
              <AllInboxIcon fontSize="inherit" />
            </IconButton>
          </Badge>
        </Tooltip>
        )
    )}) },
    { key: 'website', isOnlyChildren: true, children: (({ data })=>{
      return (
        data.website != null && (
          <Tooltip title="Website">
            <IconButton onClick={() => {window.open(data.website)}} aria-label="Website" size="small">
              <OpenInBrowserIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )
    )}) },
  ];

  return (
    <Page
      className={classes.root}
      title="Theme list."
    >
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth={false}>
          <Toolbar 
            onClickSearch={handleSearch}
            onCLickAdd={handleAdd}
          />
          <div
            draggable
            onDragStart={e => {e.preventDefault(); e.stopPropagation(); return false;}} 
            onContextMenu={(e)=>  {e.preventDefault(); e.stopPropagation(); return false;}}
          >
            <TableWrapper 
              title="Theme list"
              datas={themes}
              headCells={headCells}
              bodyCells={bodyCells}
              isNumbering={true}
            />
          </div>
        </Container>
      </Box>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({
  }),
  (dispatch) => ({
    SupplierActions: bindActionCreators(SupplierActions, dispatch),
  })
)(ThemeList);
