import React from 'react';
import {
  makeStyles,
} from '@material-ui/core';

import SmartEditor from 'src/components/Common/SmartEditor';

const useStyles = makeStyles((theme) => ({
  root: {},
  smartEditorWrapper: {
    padding: theme.spacing(1, 1.5)
  },
}));

const CommentInformation = ({ placeHolder, initialValue, initialHeight, setCommentRef }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.smartEditorWrapper}>
        <SmartEditor
          placeHolder={placeHolder}
          initialValue={initialValue}
          initialHeight={initialHeight}
          setCommentRef={setCommentRef}
        />
      </div>
    </React.Fragment>
  );
}

export default CommentInformation;