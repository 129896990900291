import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EmailIcon from '@material-ui/icons/Email';
import CancelIcon from '@material-ui/icons/Cancel';

import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import Page from 'src/components/Page';
import { SimpleToastWrapper } from 'src/components/Common';
import { ADMIN_ADDR } from 'src/components/Common/Constants';
import TableWrapper from 'src/components/Common/Grid/TableWrapper';
import Toolbar from 'src/components/Group/List/Toolbar';
import * as GroupActions from 'src/reducers/Group/actions';


import './style.css';

type Props = {
  history: any,
  GroupActions: any,
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const HotelListView = (props: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [groupCode, setGroupCode] = useState('');
  const [startDate, setStartDate] = useState(moment(new Date()).add(-10, 'days'));
  const [endDate, setEndDate] = useState(moment(new Date()).add(170, 'days'));

  React.useEffect(() => { 
      props.GroupActions.getHotelList({ startDate: startDate, endDate: endDate, groupCode, navigate }); 
    }, []);

  const onClickSearch = () => {
    // Toolbar limitation.
    if (endDate.diff(startDate, 'days') > 181) {
      SimpleToastWrapper({
        content: 'You can search less than "180" days.',
        type: 'error',
        alignItem: 'left',
      });

      return false;
    }
    props.GroupActions.getHotelList({ startDate: startDate, endDate: endDate, groupCode, navigate }); 
  }

  const onClickRQForFixed = ({ groupNo, hotelListNo, rqFixed, date, roomTypeList, hotelName }) => {
    let str = '';
    if (roomTypeList.startsWith('[')) {
      const list = [];
      const jsonData = JSON.parse(roomTypeList);
      jsonData.filter(d=>d.cnt>0).map(d=>{list.push((d.text==undefined?d.type:d.text)+' ('+d.cnt+')')});

      str += '\n     ' + list.join(', ');
    }

    window.confirm("Would you like to make a request to fix this hotel?"+str) &&
      props.GroupActions.setGroupHotelRqForFixed({ groupNo, hotelListNo, rqFixed, accommodationDate: date, hotelName, navigate }); 
  }

  const headCells = [
    { id: 'groupCode', label: 'Group Code', align: 'left', isSortable:true },
    { id: 'hotelName', label: 'Hotel Name', align: 'left', isSortable:true },
    { id: 'roomTypeList', label: 'Room Type', align: 'left', isSortable:true },
    { id: 'cityName', label: 'Location', align: 'left', isSortable:true },
    { id: 'date', label: 'Accommodation Date', align: 'left', isSortable:true },
    { id: 'fixed', label: 'Fixed' },
    { id: 'optionDate', label: 'Option Date', align: 'right', isSortable:true },
    { id: 'cancelableDate', label: 'Cancellation Date', align: 'right', isSortable:true },
  ];
  const bodyCells = [
    { key: 'groupCode', isOnlyChildren: true, children: (({ data }) => (
      <div onDoubleClick={() => { window.open(`/group/${data.groupNo}`) }}>
        <span style={{width: '100px', fontSize: '11px'}}>{data.groupCode}</span>
        <Tooltip title={`Hotel list of ${data.groupCode}`}>
          <IconButton onClick={() => {
              window.open(`${ADMIN_ADDR}/b2b/preview/hotelList/${data.groupNo}`, `Hotel list of ${data.groupCode}`, `width=815, height=1000,location=no,status=no,scrollbars=yes`);
              window.open(`/group/${data.groupNo}`);
            }} aria-label={`Hotel list of ${data.groupCode}`} size="small">
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      )), align: 'left' },
    { key: 'hotelName', align: 'left' },
    { key: 'roomTypeList', align: 'left', isOnlyChildren: true, children: (({ data }) => {
      if (!data.roomTypeList.startsWith('[')) return (<span> </span>);

      const list = [];
      const jsonData = JSON.parse(data.roomTypeList);
      jsonData.filter(d=>d.cnt>0).map(d=>{list.push((d.text==undefined?d.type:d.text)+' ('+d.cnt+')')});

      return (<span> {list.join(', ')} </span>);
    }) },
    { key: 'cityName', align: 'left' },
    { key: 'date', align: 'left' },
    { key: 'isFixed', isOnlyChildren: true, children: (({ data }) => (
      data.isFixed === 1 ? 
      (<span>Yes</span>) : 
      (
        <React.Fragment>
        {
          data.hotelListNo !== null && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={(data.isRQFixed!==1)?false:true}
              className={classes.button}
              endIcon={(<EmailIcon />)}
              onClick={
                ()=>{
                  onClickRQForFixed({...data, rqFixed: (data.isRQFixed!==1?1:0)});
                }}
            >
              RQ for fixed
            </Button>
          )
        }
        </React.Fragment>
    ))) },
    { key: 'optionDate', align: 'right', isOnlyChildren: true, children: (({ data }) => (<span>{data.optionDate}</span>)) },
    { key: 'cancelableDate', align: 'right' },
  ]

  return (
    <Page
      className={classes.root}
      title="Hotel option list"
    >
      <Container maxWidth={false}>
        <Toolbar 
          className="toobarCustom"
          startDate={startDate}
          setStartDate={setStartDate}

          endDate={endDate}
          setEndDate={setEndDate}

          groupCode={groupCode}
          setGroupCode={setGroupCode}

          onClickSearch={onClickSearch}
        />
        <Box mt={3}>
          <TableWrapper 
            title='Hotel option list'
            datas={props.hotelList}
            headCells={headCells}
            bodyCells={bodyCells}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({ 
    hotelList: state.hotelList 
  }),
  (dispatch) => ({GroupActions: bindActionCreators(GroupActions, dispatch)})
)(HotelListView);
