import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Box,
  Tooltip,
  IconButton,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';

import EmailIcon from '@material-ui/icons/Email';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AttachmentIcon from '@material-ui/icons/Attachment';

import Page from 'src/components/Page';
import ExpandTableWrapper from 'src/components/Common/Grid/ExpandTableWrapper';
import { SimpleToastWrapper } from 'src/components/Common';

import * as GroupActions from 'src/reducers/Group/actions';
import * as GroupServices from 'src/services/GroupServices';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const GroupCommentList = (props) => {
  const classes = useStyles();

  const { groupNo } = useParams();
  const navigate = useNavigate();
  
  const { GroupActions, groupBasicInformation, groupComments } = props;

  React.useEffect(() => { 
      // const groupNo = props.match.param.groupNo;
      GroupActions.getGroupComments({ groupNo, navigate }); 
    }, []);

  const isSentEmail = (isSentEmail) => (
    isSentEmail === true
      ? (<React.Fragment>&nbsp;&nbsp;<Tooltip style={{ color:'#0000af', fontWeight:'600', fontSize: '20px', backgroundColor:'#dadaff', height:'16px', position:'relative', top:'3px', borderRadius:'50%' }} title="Email sent"><EmailIcon fontSize="inherit" /></Tooltip></React.Fragment>)
      : (<React.Fragment></React.Fragment>)
  )
  const isNew = (isNew) => (
    isNew === true
      ? (<React.Fragment>&nbsp;&nbsp;<Tooltip style={{ color:'#af0000', fontWeight:'600', fontSize: '20px', backgroundColor:'#ffdada', height:'16px', position:'relative', top:'3px', borderRadius:'50%' }} title="New"><PriorityHighIcon fontSize="inherit" /></Tooltip></React.Fragment>)
      : (<React.Fragment></React.Fragment>)
  )
  const isAttatched = (isAttatched) => (
    isAttatched === 1
      ? (<React.Fragment>&nbsp;&nbsp;<Tooltip style={{ color:'#000000', fontWeight:'200', fontSize: '20px', backgroundColor:'#dadada', height:'16px', position:'relative', top:'3px', borderRadius:'50%' }} title="Attatched"><AttachmentIcon fontSize="inherit" /></Tooltip></React.Fragment>)
      : (<React.Fragment></React.Fragment>)
  )

  const handleOpenReplyPopup = (groupNo, data) => {
    // If operator is null, block open reply popup.
    if (data.commentNo === 0 && groupBasicInformation.operator === "") {
        SimpleToastWrapper({
          content: 'Operator hasn\'t been set. Please set it first.',
          type: 'error',
          alignItem: 'left',
        });

        return false;
    }

    const height = (data.commentNo==0) ? '585' : '645';
    window.open(`/popup/group/${groupNo}/comment/${data.commentNo}`, `Reply to ${data.subject}.`,`width=600,height=${height},location=no,status=no,scrollbars=yes`);
  }

  const headCells = [
    { id: 'subject', label: 'Subject', align: 'left' },
    { id: 'sender', label: 'Sender', className: 'width160' },
    { id: 'recipient', label: 'Recipient', className: 'width160' },
    { id: 'date', label: 'Date', className: 'width180' },
    { id: 'reply', label: 'Reply', className: 'tableCellIndex' },
  ];
  const bodyCells = [
    { key: 'subject', align: 'left', isOnlyChildren: true, 
      children: (({ data }) => ((data.parentCommentNo !== null) ? 
      (<span>&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:'grey'}}>⇢</span>&nbsp;&nbsp;{data.subject}&nbsp;&nbsp;{isAttatched(data.isAttatched)}&nbsp;{isSentEmail(data.isSendEmail)}&nbsp;{isNew(groupBasicInformation.customerNo===data.recipientCustomerNo ? !data.isRecipientCheck  : false)}</span>) : 
      (<span>{data.subject}&nbsp;&nbsp;{isAttatched(data.isAttatched)}&nbsp;{isSentEmail(data.isSendEmail)}&nbsp;{isNew(groupBasicInformation.customerNo===data.recipientCustomerNo ? !data.isRecipientCheck  : false)}</span>))) },
    { key: 'senderName' },
    { key: 'recipientName' },
    { key: 'registeredDate', isOnlyChildren: true, children: (({ data }) => (<span>{moment(data.registeredDate).format('YY/MM/DD HH:mm')}</span>))},
    { key: 'reply', isOnlyChildren: true, children: (({ data })=>(<IconButton onClick={() => (handleOpenReplyPopup(groupNo, data))} aria-label="reply" className={classes.margin} size="small"><OpenInNewIcon fontSize="inherit" /></IconButton>)) }
  ];

  const contentToHtml = (data) => {
    return (
      <div onClick={downloadHandler} dangerouslySetInnerHTML={ {__html: data.contents} }>
      </div>
    )
  }

  const downloadHandler = async (e) => {
    if (e.target.className === 'downloadItem') {
      const fileUrl = document.getElementById(e.target.id).getAttribute('fileurl');
      
      const datas = await GroupServices.getFile({ groupNo: groupNo, fileUrl: fileUrl });
      window.open(datas.data);
    }
  }

  return (
    <Page
      className={classes.root}
      title="Group comments."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <ExpandTableWrapper 
          title={groupBasicInformation.groupCode}
          expandButtonActions={[{label:(<React.Fragment><OpenInNewIcon fontSize="inherit" />&nbsp;New message</React.Fragment>),handleMethod: (() => handleOpenReplyPopup(groupNo,{commentNo: 0}))}]}
          datas={groupComments}
          headCells={headCells}
          bodyCells={bodyCells}
          contentFormat={contentToHtml}
        />
      </Box>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({
    groupBasicInformation: state.groupBasicInformation,
    groupComments: state.groupComments,
  }),
  (dispatch) => ({
    GroupActions: bindActionCreators(GroupActions, dispatch),
  })
)(GroupCommentList);
