// @flow

// URL : https://www.npmjs.com/package/react-spinner-material
import React from 'react';
import Spinner from 'react-spinner-material';

import './LoadingSpinner.css';

type Props = {
  styleClass: boolean,
  visible: number,
  text: string,
};
type State = {};

class LoadingSpinner extends React.Component<Props, State> {
  static defaultProps = { 
    text: 'Loading ...',
  };

  render() {
    const { styleClass, visible, text } = this.props;

    if (visible <= 0) return null;

    return (
      <React.Fragment>
        <div id="lsWrapper" className={styleClass}>
          <div class="lsSpinner">
            <Spinner radius={30} color="#dae5f5" stroke={3} visible={true} />
          </div>
          <div className="lsContent">
            <span>{text}</span>
          </div>
        </div>
        <div className="lsWrapperBackground">
        </div>
      </React.Fragment>
    )
  }
}

export default LoadingSpinner;
