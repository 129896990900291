import * as CommonActions from './Common/actions';
import { SimpleToastWrapper } from 'src/components/Common';

export const requestWrapper = ( requestMethod, successHandler, errorHandler, navigate ) => (dispatch, getState) => {
    dispatch(CommonActions.fetchLoadingStart());

    requestMethod
      .then((response) => {
        if ( successHandler !== undefined && successHandler !== null ) {
          successHandler(response);
        }
      }).catch((error) => {
        if( error.response === undefined ) {
          SimpleToastWrapper({
            content: 'Please try again... (Server error:10001)',
            type: 'error',
            alignItem: 'left',
          });
        }
        else if( error.response.status === 400 ) {
          SimpleToastWrapper({
            content: 'Login failed!!',
            type: 'error',
            alignItem: 'left',
          });
        } else if( error.response.status === 401 ) {
          if (window.document.location.pathname !== '/login') {
            SimpleToastWrapper({
              content: 'Login session is expired...',
              type: 'error',
              alignItem: 'left',
            });
            navigate("/login?targetUrl=" + window.document.location.pathname);
          }
        } else {
          SimpleToastWrapper({
            content: 'Please try again... (Server error:'+error.response.status+')',
            type: 'error',
            alignItem: 'left',
          });
        }
      }).finally(function () {
        dispatch(CommonActions.fetchLoadingFinish());
      });

}