import React, { useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ExpandTableWrapper from 'src/components/Common/Grid/ExpandTableWrapper';
import * as GroupActions from 'src/reducers/Group/actions';

const EventList = ({ classes, notices, ...rest }) => {

  const [expanded, setExpanded] = useState(false);

  const headCells = [
    { id: 'date', label: 'Date', align: 'left', width: '240px' },
    { id: 'title', label: 'Event', align: 'left' },
  ];
  const bodyCells = [
    { key: 'date', align: 'left', isOnlyChildren: true, children: (({ data }) => (<span style={{fontWeight: '500'}}>{data.startDate} {data.startDate!==data.endDate && (<React.Fragment> ~ {data.endDate}</React.Fragment>)}</span>)) },
    { key: 'title', align: 'left' },
  ];

  return (
    <Grid>
      <Accordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Events</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExpandTableWrapper
            datas={notices}
            headCells={headCells}
            bodyCells={bodyCells}
            contentFormat={(data) => {
              return (
              <div>
                {
                  (data.detail!==null && data.detail!==undefined && data.detail.trim()!=='') && (
                    <React.Fragment>
                      <div dangerouslySetInnerHTML={ {__html: data.detail} }  style={{padding: '5px 15px'}}/>
                    </React.Fragment>
                  )
                }
              </div>
            )}}
            isNumbering={true}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch) => ({GroupActions: bindActionCreators(GroupActions, dispatch)})
)(EventList);

