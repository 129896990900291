import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Box,
    Card,
    Tooltip,
    IconButton,
    Grid,
    makeStyles
  } from '@material-ui/core';
  
import Page from 'src/components/Page';
import * as SupplierActions from 'src/reducers/Supplier/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Contents = (props) => {
  const classes         = useStyles();
  const { supplierNo }  = useParams();
  const navigate        = useNavigate();
  const [data, setData] = useState({});

  const { SupplierActions } = props;

  React.useEffect(() => { 
    // const groupNo = props.match.param.groupNo;
    SupplierActions.getContents({ supplierNo, successHandler: setData, navigate }); 
  }, []);
  return (
    <Page
      className={classes.root}
      title="Supplier contents."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        style={{padding: '5px 15px'}}
      >
        {
          (data.supplier!==undefined) && (
              <React.Fragment>
              <h1>Supplier Name: {data.supplier.name1}</h1>
              <br/>
              <br/>

              { data.overviews.length!==0 && (<h2>[Overview]</h2>)}
              {
                data.overviews.map((c) => (
                  <div style={{padding: '5px 15px'}}>
                    <h3>[{c.subject}]</h3>
                    <div dangerouslySetInnerHTML={ {__html: c.content} } />
                    {
                      (c.images!==null && c.images!==undefined && c.images.length!==0) && (
                        <Grid container style={{padding: '10px 5px'}}>
                            {
                              c.images.map((i)=>(
                                <Grid item lg={3} md={4} sm={6} style={{textAlign:'center'}}>
                                  <img src={"https://euroscope-supplier.s3.eu-central-1.amazonaws.com/"+i} style={{width:'90%', height:'90%'}} />
                                </Grid>
                              ))
                            }
                        </Grid>
                      )
                    }
                  </div>
                ))
              }
              <br/>
              { data.contents.length!==0 && (<h2>[Contents]</h2>)}
              {
                data.contents.map((c) => (
                  <div style={{padding: '5px 15px'}}>
                    <h3>[{c.subject}]</h3>
                    <div dangerouslySetInnerHTML={ {__html: c.content} } />
                    {
                      (c.images!==null && c.images!==undefined && c.images.length!==0) && (
                        <Grid container style={{padding: '10px 5px'}}>
                            {
                              c.images.map((i)=>(
                                <Grid item lg={3} md={4} sm={6} style={{textAlign:'center'}}>
                                  <img src={"https://euroscope-supplier.s3.eu-central-1.amazonaws.com/"+i} style={{width:'90%', height:'90%'}} />
                                </Grid>
                              ))
                            }
                        </Grid>
                      )
                    }
                  </div>
                ))
              }
            </React.Fragment>
          )
        }
      </Box>
    </Page>
  );
};

export default connect(
    (state, ownProps) => ({
    }),
    (dispatch) => ({
      SupplierActions: bindActionCreators(SupplierActions, dispatch),
    })
  )(Contents);
  