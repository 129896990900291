import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonGroup,
  Grid,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import momentIterator from 'moment-iterator';

import { FORMAT_DATE } from 'src/components/Common/Constants'; 

import SimplePlanItem from './Item';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: '6px',
    paddingLeft: '15px',
  },
  chipWrapper: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  accordionArrow: {
    padding: '0px'
  },
  accordionContent: {
    display: 'block',
  }
}));

const SimplePlan = ({ startDate, endDate, isNoHotel, isNoRestaurant, startLocation, endLocation, itineraryList, setItineraryList }) => {
  const classes = useStyles();

  React.useEffect(()=>{
    // startDate, endDate is set.
    if (endDate!==null && startDate!==null) {
      const days = momentIterator(startDate, endDate).range('days',{ format:FORMAT_DATE });
      days.push(endDate.format(FORMAT_DATE));

      // Itinerary setting by start/end date
      const itineraryListTemp = [];
      days.map((day,index) => {
        if (itineraryList[index] !== undefined) {
          itineraryListTemp.push({
            ...itineraryList[index],
            date: day,
          });
        } else {
          itineraryListTemp.push({
            date: day,
            cities: [],
            entrances: [],
            hotels: [],
            technicalVisits: [],
            comment: '',
            isLunch: !isNoRestaurant,
            isDinner: !isNoRestaurant,
            isHotel: !isNoHotel,
          });
        }
      });
      setItineraryList(itineraryListTemp);
    }
  }, [startDate, endDate]);

  const onChangeOrder = (event, index) => {
    event.stopPropagation();

    let copied = [...itineraryList];
    let spliced = copied.splice(index, 1);
    copied.splice(index+1, 0, spliced[0]);

    const prevDate = spliced[0].date;
    const nextDate = copied[index].date;

    copied[index].date = prevDate;
    copied[index+1].date = nextDate;

    setItineraryList( copied );
  }
  const onChangeItem = ( type, date, value) => {
    setItineraryList(
      itineraryList.map((itinerary)=>{
        if (itinerary.date === date) {
          let target = {...itinerary};
          target[type] = value;
          
          return {...target};
        } else {
          return {...itinerary};
        }
      })
    );
  };

  const getAllCities = (startLocation, cities) => {
    let temp = [];
    if (startLocation!==undefined && startLocation!==null) {
      temp.push(startLocation);
    }
    temp = temp.concat(cities);
    return temp;
  }
  const getStartLocation = (index) => {
    if (index===0) {
      return startLocation;
    } else if (itineraryList[index-1].cities.length===0) {
      return getStartLocation(index-1);
    } else {
      return itineraryList[index-1].cities[itineraryList[index-1].cities.length-1];
    }
  }

  return (
    <React.Fragment>
      {
        itineraryList.map((data,index) => {
          const startCity = getStartLocation(index);
          const allCities = getAllCities(startCity, data.cities);
          const endCity = (allCities.length===0 ? null : allCities[allCities.length-1]);
          const cityIds = allCities.map((city)=>(city.key));

          const isEnd = index===(itineraryList.length-1);

          return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <ButtonGroup
                orientation="vertical"
                color="primary"
              >
                <IconButton aria-label="movePositionPrev" size="small" className={classes.accordionArrow} disabled={index !== 0?false:true} onClick={(event)=>{onChangeOrder(event,index-1)}}>
                  <ArrowDropUpIcon size="small"/>
                </IconButton>
                <IconButton aria-label="movePositionPrev" size="small" className={classes.accordionArrow} disabled={index !== itineraryList.length-1?false:true} onClick={(event)=>{onChangeOrder(event,index)}}>
                  <ArrowDropDownIcon size="small"/>
                </IconButton>
              </ButtonGroup>
              <div style={{margin:'auto 0', display:'flex'}}>
                <EventOutlinedIcon fontSize="small"/>
                &nbsp;&nbsp;<Typography className={classes.heading}>{`${(index+1)}. ${data.date}`}</Typography>
                <Typography className={classes.subHeading}>
                  {((startCity!==undefined && startCity!==null) || data.cities.length!==0) && (<React.Fragment>( </React.Fragment>)}
                  {(startCity!==undefined && startCity!==null) && (<React.Fragment>{startCity.label}</React.Fragment>)}
                  {((startCity!==undefined && startCity!==null) && data.cities.length!==0) && (<React.Fragment>,&nbsp;</React.Fragment>)}
                  {data.cities.length!==0 && (<React.Fragment>{data.cities[0].label}</React.Fragment>)}
                  {data.cities.length>1 && (<React.Fragment> and other</React.Fragment>)}
                  {data.cities.length>2 && (<React.Fragment>s</React.Fragment>)}
                  {((startCity!==undefined && startCity!==null) || data.cities.length!==0) && (<React.Fragment> )</React.Fragment>)}
                </Typography>
              </div>                  
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              <Grid container spacing={2} >
                <Grid item xs={12} style={{textAlign:'right'}}>
                  <FormControlLabel
                    control={
                    <Switch 
                      checked={isNoRestaurant?false:data.isLunch} 
                      disabled={isNoRestaurant} 
                      size="small" 
                      onChange={(event) => onChangeItem("isLunch", data.date, event.target.checked)} 
                      name="isLunch" />}
                    label="Lunch reservation"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    control={
                    <Switch 
                      checked={isNoRestaurant?false:data.isDinner} 
                      disabled={isNoRestaurant} 
                      size="small" 
                      color="primary" 
                      onChange={(event) => onChangeItem("isDinner", data.date, event.target.checked)} 
                      name="isDinner" />}
                    label="Dinner reservation"
                    labelPlacement="start"
                  />
                  {
                    !isEnd && (
                      <FormControlLabel
                        control={
                        <Switch 
                          checked={isNoHotel?false:data.isHotel} 
                          disabled={isNoHotel} 
                          size="small" 
                          color="primary" 
                          onChange={(event) => onChangeItem("isHotel", data.date, event.target.checked)} 
                          name="isHotel" />}
                        label="Hotel reservation"
                        labelPlacement="start"
                      />
                    )
                  }
                </Grid>
                <Grid item md={5} xs={12}>
                  <SimplePlanItem.City 
                    label="City"
                    type="cities"
                    date={data.date}
                    datas={data.cities}
                    setDatas={onChangeItem}
                    isStart={index===0}
                    isEnd={isEnd}
                    startLocation={startCity}
                    endLocation={endLocation}
                    isMovable={true}
                  />
                  {
                    !isEnd && (
                      <SimplePlanItem.Default 
                        label="Hotel"
                        type="hotels"
                        date={data.date}
                        datas={data.hotels}
                        setDatas={onChangeItem}
                        endCity={endCity}
                        isMovable={false}
                        isDisabled={((endCity!==null)?(isNoHotel || !data.isHotel):true)}
                      />
                    )
                  }
                </Grid>
                <Grid item md={7} xs={12}>
                  <SimplePlanItem.Default 
                    label="Entrance"
                    type="entrances"
                    date={data.date}
                    datas={data.entrances}
                    setDatas={onChangeItem}
                    cityIds={cityIds}
                    isMovable={true}
                    isDisabled={(allCities.length>0?false:true)}
                  />
                  <SimplePlanItem.Default 
                    label="Technical Visit"
                    type="technicalVisits"
                    date={data.date}
                    datas={data.technicalVisits}
                    setDatas={onChangeItem}
                    cityIds={cityIds}
                    isMovable={false}
                    isDisabled={(allCities.length>0?false:true)}
                  />
                  <SimplePlanItem.Comment 
                    label="Comment"
                    type="comment"
                    date={data.date}
                    comment={data.comment}
                    setComment={onChangeItem}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )})
      }
    </React.Fragment>
  );
}

export default SimplePlan;