import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Card,
  CardHeader,
  CardContent,
  Chip,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    margin: 0,
    marginBottom: '15px',
  },
  cardHeader: {
    width: '100%',
    paddingBottom: '5px',
  },
  cardContent: {
    width: '100%',
    paddingTop: '5px',
    paddingBottom: '5px',
      "&:last-child": {
        paddingBottom: '5px',
      }
  },
  chipWrapper: {
    borderRadius: '16px',
    backgroundColor: '#e0e0e0',
    margin: theme.spacing(0.5, 2),
  },
}));

const ItineraryItem = ({ label, datas, isShowContents }) => {
  const classes = useStyles();

  const onClickSupplyHandler = (data) => {
    window.open(`/popup/supplier/contents/${data.key}`, `Contents of ${data.label}`, `width=750, height=1200,location=no,status=no,scrollbars=yes`)
  }
  
  return (
    <Card className={classes.root}>
      <CardHeader 
        title={label}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <Paper component="ul" className={classes.root}>
          {datas.map((data, index) => {
            return (
              <li key={data.key}>
                <div className={classes.chipWrapper}>
                    <Chip
                      label={(index+1) + ". " +data.label}
                      className={classes.chip}
                      size="small" 
                      onClick={isShowContents===true?()=>onClickSupplyHandler(data):()=>console.log("no")}
                    />
                </div>
              </li>
            );
          })}
        </Paper>
      </CardContent>
    </Card>
  )
};

export default ItineraryItem;