// @flow
import React, { useState } from 'react';

// URL : https://github.com/airbnb/react-dates
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker,isInclusivelyAfterDay } from 'react-dates';

import { FORMAT_DATE } from '../Constants';

import './DatePicker.css';
import 'react-dates/lib/css/_datepicker.css';

type Props = {
  onChange: Function,
  startDate: string,
  endDate: string,
}
type State = {
  startDate: moment,
  endDate:   moment,
  focusedInput: ?string,
  calendarFocused: boolean,
  isOutsideRange: ?boolean,
}

const RangeDatePickerWrapper = ({ startDate, endDate, isOutsideRange, minDate, onChange, isDisable }) => {
  const [states, setStates] = useState({
    focusedInput: null,
    calendarFocused: false,
    isOutsideRange: (isOutsideRange===undefined ? false : isOutsideRange)
  });

  return(
    <DateRangePicker
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId="startDate" // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId="endDate" // PropTypes.string.isRequired,
      onDatesChange={onChange} // PropTypes.func.isRequired,
      focusedInput={states.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={focusedInput => setStates({ focusedInput })} // PropTypes.func.isRequired,
      displayFormat={FORMAT_DATE}
      numberOfMonths={1}
      minimumNights={0}
      // isOutsideRange={() => (isOutsideRange===undefined ? false : true)}
      isOutsideRange={day => (isOutsideRange===undefined ? false :!isInclusivelyAfterDay(day, moment()))}
      disabled={isDisable!==undefined?isDisable:false}
    />
  );
};

export default RangeDatePickerWrapper;