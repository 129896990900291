import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import moment from 'moment';
import queryString from 'query-string';

import Page from 'src/components/Page';
import { SimpleToastWrapper } from 'src/components/Common';

import ButtonBox from 'src/components/Group/Edit/ButtonBox';
import BasicInform from 'src/components/Group/Edit/BasicInform';
import SimplePlan from 'src/components/Group/Edit/SimplePlan';
import CommentInformation from 'src/components/Group/Edit/CommentInformation';
import AttatchedInformation from 'src/components/Group/Edit/AttatchedInformation';

import { ADMIN_ADDR } from 'src/components/Common/Constants';
import * as GroupActions from 'src/reducers/Group/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EditGroupView = ({ GroupActions }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [isEdit, setIsEdit] = useState(false);
  const [groupNo, setGroupNo] = useState(0);
  const [groupTempNo, setGroupTempNo] = useState(0);
  const [groupCode, setGroupCode] = useState('');
  const [grpB2BObject, setGrpB2BObject] = useState({});

  // Variable for group information.
  const [dates, setDates] = useState({startDate:null, endDate:null});
  const [startLocation, setStartLocation] = useState(null);
  const [endLocation, setEndLocation] = useState(null);
  const [roomList, setRoomList] = useState([{cnt: 0, code: "19-1", pax: "1", text: "SGL"},{cnt: 0, code: "19-4", pax: "3", text: "TRP"},{cnt: 0, code: "19-3", pax: "2", text: "TWN"}]);
  const [isNoHotel, setIsNoHotel] = useState(false);
  const [isNoRestaurant, setIsNoRestaurant] = useState(false);
  const [isNoBus, setIsNoBus] = useState(false);

  const [itineraryList, setItineraryList] = useState([]);
  const [commentRef, setCommentRef] = useState(null);
  const [commentValue, setCommentValue] = useState("");
  const [attatchedList, setAttatchedList] = useState([]);

  const set = (id, data) => (document.getElementById(id).value = data);
  const value = (id) => (document.getElementById(id).value);
  const valueSel = (id) => ((value(id)!=='') ? document.querySelector("option[value='"+value(id)+"']").text : '');

  const ids = ['groupCode', 'groupType', 'operator', 'groupGrade', 'startFlightNo', 'endFlightNo', 'paxNo', 'nationality', 'transport', 'guideType'];
  const idsSel = ['groupType','operator','groupGrade','nationality','transport','guideType'];

  const idsSame = ['groupCode','groupType','operator','groupGrade','startTime','startFlightNo','endTime','endFlightNo','paxNo','nationality','transport','guideType','isNoHotel','isNoRestaurant','isNoBus','comment'];
  const getAllObject = () => {
    setGroupCode(value('groupCode'));
    let params = {
      groupNo,

      startLocation,
      startDate: ((dates.startDate!==undefined&&dates.startDate!==null) ? dates.startDate.format('YYYY-MM-DD') : ''),
      startTime: value('arr_hour') + ':' + value('arr_minute'),

      endLocation,
      endDate: ((dates.endDate!==undefined&&dates.endDate!==null) ? dates.endDate.format('YYYY-MM-DD') : ''),
      endTime: value('dept_hour') + ':' + value('dept_minute'),
      
      roomList,

      itineraryList,

      isNoHotel,
      isNoRestaurant,
      isNoBus,

      comment: commentRef.getHtml(),
    };
    ids.map((data) => (params[data] = value(data)));
    idsSel.map((data) => (params[data+'Name'] = valueSel(data)));

    return params;
  };
  const setAllObject = (params) => {
    setGroupNo(params.groupNo);
    setGroupCode(params.groupCode);

    setStartLocation(params.startLocation);
    setEndLocation(params.endLocation);

    setRoomList(params.roomList);

    setItineraryList(params.itineraryList);

    setIsNoHotel(params.isNoHotel);
    setIsNoRestaurant(params.isNoRestaurant);
    setIsNoBus(params.isNoBus);

    setDates({
      startDate: (params.startDate==='') ? null : moment(params.startDate, 'YYYY-MM-DD'),
      endDate: (params.endDate==='') ? null : moment(params.endDate, 'YYYY-MM-DD'),
    })

    set('arr_hour', params.startTime.split(':')[0]);
    set('arr_minute', params.startTime.split(':')[1]);
    set('dept_hour', params.endTime.split(':')[0]);
    set('dept_minute', params.endTime.split(':')[1]);

    setCommentValue(params.comment);

    if (commentRef !==null ) {
      commentRef.setHtml(params.comment);
    }

    ids.map((data) => set(data, params[data]));
  }

  const toast = (message) => {SimpleToastWrapper({content: message,type: 'error',alignItem: 'left',})};

  const validationCheck = () => {
    // Validation check.
    if (value('groupCode')==='') { toast(`Please input group code!!`); return false; }
    if (dates===null || dates.startDate===undefined || dates.startDate===null || dates.endDate===undefined || dates.endDate===null) { toast(`Please input dates!!`); return false; }
    if (startLocation===null) { toast(`Please choose start location!!`); return false; }
    if (endLocation===null) { toast(`Please choose end location!!`); return false; }
    if (value('operator')==='') { toast(`Please select operator!!`); return false; }
    if (roomList.length===0) { toast(`Please insert room type!!`); return false; }

    return true;
  }
  const changedCheck = () => {
    const params = getAllObject();

    for (var i=0; i<idsSame.length; i++) {
      if (params[idsSame[i]]!==grpB2BObject[idsSame[i]]) {
        return true;
      }
    }
    // Start location
    if (params.startLocation.key!==grpB2BObject.startLocation.key || params.startLocation.label!==grpB2BObject.startLocation.label) {
        return true;
    }
    // End location
    if (params.endLocation.key!==grpB2BObject.endLocation.key || params.endLocation.label!==grpB2BObject.endLocation.label) {
        return true;
    }
    // RoomList check
    if (params.roomList.length===grpB2BObject.roomList.length) {
      for (var i=0; i<params.roomList.length; i++) {
        if (params.roomList[i].cnt!=grpB2BObject.roomList[i].cnt || params.roomList[i].code!==grpB2BObject.roomList[i].code) {
          return true;
        }
      }
    } else {
        return true;
    }
    // ItineraryList check
    for (var i=0; i<params.itineraryList.length; i++) {
      let isChanged = false;

      const a = params.itineraryList[i];
      const b = grpB2BObject.itineraryList[i];

      const idsIl = ['comment', 'isLunch', 'isDinner', 'isHotel'];
      for (var j=0; j<idsIl.length; j++) {
        if (a[idsIl[j]]!==b[idsIl[j]]) { return true; }
      }

      if (isChangedItineraryItem(a.cities, b.cities)) {
        return true;
      }
      if (isChangedItineraryItem(a.entrances, b.entrances)) {
        return true;
      }
      if (isChangedItineraryItem(a.technicalVisits, b.technicalVisits)) {
        return true;
      }
      if (isChangedItineraryItem(a.hotels, b.hotels)) {
        return true;
      }
    }

    return false;
  }
  const isChangedItineraryItem = (a,b) => {
    if (a.length!==b.length) {
      for (var j=0; j<a.length; j++) {
        if (a[j].key!==b[j].key) {
          return true;
        }
      }
    } else {
      return true;
    }
    return false;
  }

  const openPreview = () => {
    if (!validationCheck()) return false;

    const formData = new FormData();
    formData.append('groupTempNo', groupTempNo);
    formData.append('groupCode', value('groupCode'));
    formData.append('params', JSON.stringify(getAllObject()));

    GroupActions.saveGroupTemporary({ formData, setGroupTempNo, navigate, successHandler: (groupTempNo)=>(window.open(`${ADMIN_ADDR}/b2b/preview/temporaryPlan/${groupTempNo}`, '_blank', `width=815, height=1000,location=no,status=no,scrollbars=yes`)) });
  }

  const tempSave = () => {

    const formData = new FormData();
    formData.append('groupTempNo', groupTempNo);
    formData.append('groupCode', value('groupCode'));
    formData.append('params', JSON.stringify(getAllObject()));

    if (attatchedList.length===0 || (attatchedList.length!==0 && window.confirm("Attached files are not saved."))) {
      GroupActions.saveGroupTemporary({ formData, setGroupTempNo, navigate });
    }
  }
  const onSave = (target) => {
    if (!validationCheck()) return false;

    let params = getAllObject();
    params['target'] = target;

    const formData = new FormData();
    formData.append('target', target);
    formData.append('groupTempNo', groupTempNo);
    formData.append('basicInformation', JSON.stringify(params));
    attatchedList.map(file => { formData.append('attatchedList', file, file.name) });

    GroupActions.createGroup({ formData, navigate });
  }
  const onUpdate = () => {
    if (!validationCheck()) return false;

    const isDiff = changedCheck();
    if (isDiff===false && attatchedList.length===0) {
      SimpleToastWrapper({
        content: `Nothing has been changed!!`,
        type: 'error',
        alignItem: 'left',
      });

      return false;
    }

    let params = getAllObject();

    const formData = new FormData();
    formData.append('groupNo', groupNo);

    formData.append('params', JSON.stringify(params));

    formData.append('basicInformation', JSON.stringify(params));
    formData.append('isDiff', isDiff);
    attatchedList.map(file => { formData.append('attatchedList', file, file.name) });

    GroupActions.updateGroup({ formData, navigate });
  }


  // Initializing.
  React.useEffect(() => {
    const query = queryString.parse(location.search);

    if (query.isEdit!==undefined&&query.isEdit!==1) {
      setIsEdit(true);
    }

    if (commentRef!==null) {
      if (query.groupTempNo!==undefined&&query.groupTempNo!=='') {
        setGroupTempNo(query.groupTempNo);
        GroupActions.getTemporarySavedTourGroup({ groupTempNo: query.groupTempNo, navigate, successHandler: (data) => {
          const params = JSON.parse(data.temporarySavedTourGroup.params);
          setAllObject(params);
        } }); 
      } else if (query.groupNo!==undefined && query.groupNo!=='') {
        GroupActions.getTourGroup({ groupNo: query.groupNo, navigate, successHandler: (data) => {
          if (data.success === true) {
            const params = JSON.parse(data.tourGroup.b2bObjects.params);
            setAllObject({
              ...params,
              groupCode: data.tourGroup.basicInformation.groupCode,
              groupNo: query.groupNo
            });

            if (isEdit) {
              setGrpB2BObject({...params});
            }
          } else {
            SimpleToastWrapper({
              content: `Group is not exist!!`,
              type: 'error',
              alignItem: 'left',
            });
          }
        }});
      }
    }
  }, [commentRef]);

  /** 
  ** Parameter naming rules.
  {
      l: label
      t: type
      m: method
      ir: isRequired
      dv: defaultValue
  } */
  const basicInforms = [
    { l:'1. Group Code',              t:'text',       id:'groupCode', ir:true, d:isEdit },
    { l:'2. Group Type',              t:'select',     id:'groupType', ir:true,             opts:{ t:'codes', groupId:28, dv:'28-2' } },
    { l:'3. Operator',                t:'select',     id:'operator',  ir:true,             opts:{ t:'operators',         dv:'', iv:{key:'',value:''}, key:'id', value:'name' } },
    { l:'4. Hotel Grade',             t:'select',     id:'groupGrade',ir:true,             opts:{ t:'codes', groupId:29, dv:'' } },
    { l:'5. Start/End Information',   t:'wrapper',                      lg:6, md:12, xs:12, children: [
      { l:'(1) Start/End Dates',      t:'rangeDatePicker',            ir:true,       xs:12,dates, setDates, d:isEdit},
      { l:'(2) Arrival Time',         t:'timePicker', id:'arr',       ir:true,       xs:6, hour:0, minute:0},
      { l:'(3) Departure Time',       t:'timePicker', id:'dept',      ir:true,       xs:6, hour:23, minute:59},
      { l:'(4) Arrival Location',     t:'location',   id:'startLoc',  ir:true,       xs:6, v:startLocation, m:setStartLocation},
      { l:'(5) Departure Location',   t:'location',   id:'endLoc',    ir:true,       xs:6, v:endLocation,   m:setEndLocation},
      { l:'(6) Arrival Flight Nr.',   t:'text',       id:'startFlightNo',            xs:6},
      { l:'(7) Departure Flight Nr.', t:'text',       id:'endFlightNo',              xs:6}, ]},
    { t:'wrapper', lg:6, md:12, xs:12, children: [
      { l:'6. Pax Nr.',               t:'text',       id:'paxNo',     ir:true, md:4, xs:6, dv:31, },
      { l:'7. Nationality',           t:'select',     id:'nationality',ir:true,md:4, xs:6, opts:{ t:'codes', groupId:31, dv:'31-1' } },
      { l:'8. Transport Type',        t:'select',     id:'transport', ir:true, md:4, xs:6, opts:{ t:'codes', groupId:12, dv:'12-6' } },
      { t:'roomTypeWrapper', roomList, setRoomList, children: [
        { l:'9. Room Type',             t:'select',                      lg:4,       xs:6, opts:{ t:'codes', groupId:19, dv:'', iv:{key:'',value:''}, pv:'attrStr1' } },
        { t:'roomTypeList',                                              lg:8, md:6, xs:12, roomList, setRoomList },]}]},
    { l:'10. Guide Type',             t:'select',     id:'guideType', ir:true,             opts:{ t:'codes', groupId:52, dv:'' } },
    { l:'No hotel request',           t:'switch',     id:'isNoHotelRQ', ir:true,             v:isNoHotel, m:setIsNoHotel },
    { l:'No bus request',             t:'switch',     id:'isNoBusRQ',   ir:true,             v:isNoBus, m:setIsNoBus },
    { l:'No restaurant request',      t:'switch',     id:'isNoRestaurantRQ',ir:true,         v:isNoRestaurant, m:setIsNoRestaurant },
  ];

  return (
    <Page
      className={classes.root}
      title="Edit tour group"
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <form autoComplete="off" noValidate>
              <Card>
                <CardHeader
                  title="Basic Information"
                  subheader="The information can be edited"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    { 
                      basicInforms.map((field, index) => (<BasicInform index={index} field={field} />))
                    }
                  </Grid>
                  {
                    itineraryList.length!==0 && (
                      <React.Fragment>
                        <div style={{paddingBottom:"30px"}}>&nbsp;</div>
                        <CardHeader
                          title="Simple Plan"
                        />
                        <Divider />
                      </React.Fragment>
                    )
                  }
                  <CardContent>
                    <SimplePlan
                      isEdit={isEdit}
                      startDate={dates.startDate}
                      endDate={dates.endDate}
                      startLocation={startLocation}
                      endLocation={endLocation}
                      isNoRestaurant={isNoRestaurant}
                      isNoHotel={isNoHotel}
                      itineraryList={itineraryList}
                      setItineraryList={setItineraryList}
                    />
                  </CardContent>
                </CardContent>
                <div style={{paddingBottom:"30px"}}>&nbsp;</div>
                <Grid container>
                  <Grid lg={8} xs={12}>
                    <CardHeader
                      title="Comment"
                    />
                    <Divider />
                    <CardContent>
                      <CommentInformation
                        placeHolder="Please input the comment about this group."
                        initialValue={commentValue}
                        setCommentRef={(ref) => {
                            ref.setHtml(commentValue);
                            setCommentRef(ref);
                          }}
                      />
                    </CardContent>
                  </Grid>
                  <Grid lg={4} xs={12}>
                    <CardHeader
                      title={(isEdit===true?'Additional ':'') + "Attatch file"}
                    />
                    <Divider />
                    <CardContent>
                      <AttatchedInformation
                        attatchedList={attatchedList}
                        setAttatchedList={setAttatchedList}
                      />
                    </CardContent>
                  </Grid>
                </Grid>

                <Divider />
                <div style={{paddingBottom:"30px"}}>&nbsp;</div>
              </Card>
              <ButtonBox 
                isEdit={isEdit}
                openPreview={openPreview}
                tempSave={tempSave}
                onSave={onSave}
                onUpdate={onUpdate}
               />
            </form>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({ 
  }),
  (dispatch) => ({GroupActions: bindActionCreators(GroupActions, dispatch)})
)(EditGroupView);

