import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  InputLabel,
  Button,
  FormControlLabel,
  Switch,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import Page from 'src/components/Page';
import CommentInformation from 'src/components/Group/Comment/CommentInformation';
import AttatchedInformation from 'src/components/Group/Comment/AttatchedInformation';
import { SimpleToastWrapper } from 'src/components/Common';

import * as GroupActions from 'src/reducers/Group/actions';
import * as GroupServices from 'src/services/GroupServices';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'contents'
  },
  component: {
    paddingBottom: theme.spacing(1),
  },
  contents: {
    paddingBottom: '50px',
  },
  sendMailWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonArea: {
    position: 'absolute',
    width: '100%',
    height: '50px',
    justifyContent: 'flex-end',
    borderTop: '1px solid #cacaca',
    background: 'white',
  }
}));

const GroupCommentAdd = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const { GroupActions, groupBasicInformation, groupComment } = props;
  const { groupNo, commentNo } = useParams();

  const [ subject, setSubject ] = useState('');
  const [ isSendEmail, setIsSendEmail ] = useState(false);
  
  const [ values, setValues ] = useState({});
  const [ commentRef, setCommentRef ] = useState(null);
  const [ attatchedList, setAttatchedList ] = useState([]);
  
  React.useEffect(() => { 
      GroupActions.getGroupComment({ groupNo, commentNo, navigate, successHandler: (data) => {
        const { comment } = data;

        if (comment === null) {
          setValues({
            parentSubject: '',
            parentCommentNo: 0,
            parentParentCommentNo: 0,
            contentsHistory: '',
            senderNo: 0,
          });
        } else {
          setValues({
            parentSubject: comment.subject,
            parentParentCommentNo: comment.parentCommentNo,
            parentCommentNo: comment.commentNo,
            contentsHistory: makeContentsHistory(comment),
            senderNo: comment.senderNo,
          });
          setSubject( `FW: ${comment.subject}`);
        }
      } });
    }, []);

  const makeContentsHistory = (comment) => {
    let str = `---------- updated ${moment(comment.registeredDate).format('YY/MM/DD hh:mm')} by ${comment.senderName} ----------<br/>${comment.contents}`;
    if (comment.contentsHistory !== null) {
      str += `<br/><br/>${comment.contentsHistory}`;
    }
    return str;
  }

  const handleChange = (event) => {
    setSubject(event.target.value);
  };
  const handleChangeChecked = (event) => {
    setIsSendEmail(event.target.checked);
  };
  const downloadHandler = async (e) => {
    if (e.target.className === 'downloadItem') {
      const fileUrl = document.getElementById(e.target.id).getAttribute('fileurl');
      
      const datas = await GroupServices.getFile({ groupNo: groupNo, fileUrl: fileUrl });
      window.open(datas.data);
    }
  };

  const handleSend = (event) => {
    // Prevent default submit action.
    event.preventDefault();
    console.log(values);

    if (subject==='') {
        SimpleToastWrapper({
          content: 'Please input subject.',
          type: 'error',
          alignItem: 'left',
        });
        event.target[0].focus();

        return false;
    }
    if (commentRef.getHtml()==='') {
        SimpleToastWrapper({
          content: 'Please input content.',
          type: 'error',
          alignItem: 'left',
        });

        return false;
    }

    const params = {
      parentCommentNo: (values.parentParentCommentNo===null) ? values.parentCommentNo : values.parentParentCommentNo,
      groupNo: groupBasicInformation.groupNo,
      subject: subject,
      contents: commentRef.getHtml(),
      contentsHistory: values.contentsHistory,
      senderNo: (commentNo==="0")?0:values.senderNo,
      isRecipientCheck: false,
      isSendEmail: isSendEmail,
      isEuroscope: false,
      isValid: true,
    };

    const formData = new FormData();
    formData.append('params', JSON.stringify(params));
    attatchedList.map(file => {
      formData.append('attatchedList', file, file.name);
    });

    GroupActions.createComment({ formData: formData, navigate });
  }

  return (
    <React.Fragment>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSend}
      >
        <Page
          className={classes.root}
          title={values.parentSubject !== '' ? "Reply to " + values.parentSubject + "." : "Send message. (" + groupBasicInformation.groupCode + ")"}
        >
          <Card className={classes.root} spacing={1}>
            <CardHeader
              title={"Group Code : " + groupBasicInformation.groupCode}
            />
            <CardContent className={classes.contents}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    className={classes.component}
                    label="subject"
                    name="Subject"
                    onChange={handleChange}
                    required={true}
                    value={subject}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <InputLabel htmlFor="component-simple" required={true} shrink>Content</InputLabel>
              <div className={classes.component}>
                <CommentInformation
                  setCommentRef={setCommentRef}
                  initialHeight="200px"
                />
              </div>
              {
                values.contentsHistory !== undefined && values.contentsHistory !== '' && (
                  <div className={classes.component}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>Previous history</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div onClick={downloadHandler} dangerouslySetInnerHTML={ {__html: values.contentsHistory} }></div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )
              }
              <div className={classes.component}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>Attatched files</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AttatchedInformation
                      attatchedList={attatchedList}
                      setAttatchedList={setAttatchedList}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            </CardContent>
            <CardActions disableSpacing className={classes.buttonArea}>
                <Button size="small" color="primary" variant="contained" type="submit">
                  Send
                </Button>
            </CardActions>
          </Card>
        </Page>
      </form>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    groupBasicInformation: state.groupBasicInformation,
    groupComment: state.groupComment,
  }),
  (dispatch) => ({
    GroupActions: bindActionCreators(GroupActions, dispatch),
  })
)(GroupCommentAdd);
