// @flow

// URL : https://toasted-notes.netlify.com/

import React from 'react';

import Alert from '@material-ui/lab/Alert';

import toast from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import './SimpleToastWrapper.css';

type Props = {
  content: string,
  type: any,
};

const SimpleToastWrapper = ({
  content, type
}: Props) => (
  toast.notify(({ onClose }) => (
    <div css={{ textDecoration: 'none' }} onClick={onClose}>
      <Alert variant="filled" severity={type}>
        {content}
      </Alert>
    </div>
  ), {
    duration: 3000,
  })
);

export default SimpleToastWrapper;
