import React from 'react';
import {
  Box,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  IconButton,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { isNull, isNullStr } from "src/components/Common/Constants";

const RoomType = ({ index, field }) => {
  const handleRemoveRoomType = (code) => {
    field.setRoomList(
      field.roomList.filter(data => data.code !== code)
    )
  }
  const handleChangeRoomType = (event) => {
    field.setRoomList(
      field.roomList.map( (data) => {
        if (data.code === event.target.name) {
          return {...data, cnt: event.target.value};
        } else {
          return {...data};
        }
      })
    )
  }

  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <InputLabel htmlFor="component-simple" shrink>Selected room types ({field.roomList.length})</InputLabel>
        <Box border={1} borderRadius={16} borderColor="grey.400" style={{minHeight:'60px'}}>
          <List dense={true}>
            {
              field.roomList.map( (data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={'* ' +data.text}
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="caption" gutterBottom>
                      cnt : 
                    </Typography>
                    <TextField
                      type="number"
                      id={data.code}
                      name={data.code}
                      value={data.cnt}
                      onChange={handleChangeRoomType}
                      style={{width:'25px', paddingTop:'5px'}}
                      InputLabelProps={{
                        shrink: true,
                        style: { textAlign: 'center' }
                      }}
                    />
                    <IconButton name={data.code} onClick={() => {handleRemoveRoomType(data.code);}} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Box>
      </Grid>    
  );
}

export default RoomType;