import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LoadingBar from 'react-redux-loading-bar';

import { LoadingSpinner } from 'src/components/Common';
import NavBar from './NavBar';
import TopBar from './TopBar';

import * as AccountActions from 'src/reducers/Account/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ isLoading, AccountActions }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  React.useEffect(() => { 
    AccountActions.getAccount({ navigate });
  }, [AccountActions]);

  const onLogoutHandler = () => {
    AccountActions.logout({ navigate });
  }

  return (
    <div className={classes.root}>
      <header>
        <LoadingBar style={{ backgroundColor: 'blue', height: '5px' }}/>
      </header>
      <LoadingSpinner styleClass='loginPageLoadingSpinner' visible={isLoading}/>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} onLogoutHandler={onLogoutHandler}/>
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        onLogoutHandler={onLogoutHandler}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  isLoading: PropTypes.number,
};

export default connect(
  (state, ownProps) => ({ 
    isLoading: state.isLoading 
  }),
  (dispatch) => ({
    AccountActions: bindActionCreators(AccountActions, dispatch),
  })
)(DashboardLayout);
