import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash';
import {
  Button,
  Box,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputLabel,
  TextField,
  makeStyles
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Page from 'src/components/Page';
import { isNull, isNullStr } from "src/components/Common/Constants";
import CommentInformation from 'src/components/Group/Edit/CommentInformation';
import { SimpleToastWrapper } from 'src/components/Common';

import * as GroupServices from 'src/services/GroupServices';
import * as SupplierActions from 'src/reducers/Supplier/actions';

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    buttonArea: {
      width: '100%',
      height: '50px',
      justifyContent: 'flex-end',
      borderTop: '1px solid #cacaca',
      background: 'white',
    }
  }));

const ThemeAdd = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);
    const [loadingCountry, setLoadingCountry] = React.useState(false);
    const [loadingCity, setLoadingCity] = React.useState(false);
    const [country, setCountry] = React.useState([]);
    const [city, setCity] = React.useState([]);
    const [selCity, setSelCity] = React.useState(null);
    const [commentRef, setCommentRef] = useState(null);

    const { SupplierActions } = props;

  const inputs = [
    { l:'1. Supplier Name',     t:'text',       id:'name1', ir:true, d:isEdit },
    { l:'2. Address',           t:'text',       id:'address1', ir:true, d:isEdit },
    { l:'3. Zip Code',          t:'text',       id:'zipcode1', ir:false, d:isEdit },
    { l:'4. Country/City',      t:'city',       id:'cc', ir:true, d:isEdit },
    { l:'5. Telephone',         t:'text',       id:'telephone', ir:false, d:isEdit },
    { l:'6. Fax',               t:'text',       id:'fax', ir:false, d:isEdit },
    { l:'7. Email',             t:'text',       id:'email', ir:false, d:isEdit },
    { l:'8. Website URL',       t:'text',       id:'website', ir:true, d:isEdit },
    { l:'9. Memo',              t:'textArea',   id:'generalInfo', ir:false, d:isEdit },
  ];

  const handleInputCountryChange = (value, reason) => {
    if (value.length >= 2 && reason === 'input') {
      debounceGetCoutryListFunc(value);
    }
  }
  const handleInputCityChange = (value, reason) => {
    if (reason === 'clear') {
      setSelCity(null);
    }
    if (value.length >= 2 && reason === 'input') {
      debounceGetCityListFunc(value);
    }
  }
  const debounceGetCoutryListFunc = debounce(async (value) => {
    setLoadingCountry(true);
    const lcValue = value.toLowerCase();

    const datas = await GroupServices.getCountries({countryName: lcValue});
    let result = datas.data.cities;

    setCountry(result);
    setLoadingCountry(false);
  }, 500);
  const debounceGetCityListFunc = debounce(async (value) => {
    setLoadingCity(true);
    const lcValue = value.toLowerCase();

    const datas = await GroupServices.getCities({cityName: lcValue});
    let result = datas.data.cities;

    setCity(result);
    setLoadingCity(false);
  }, 500);

  const filterOptions = (options, { inputValue }) => {return options;};

  
  const handleCitySelect = (value, reason) => {
    if ( reason === "select-option" ) {
      setSelCity({cityId: value.key, countryId: value.countryKey});
    }
  }
  const handleCreate = () => {
      console.log(commentRef.getHtml());

      if (document.getElementById('name1').value.trim()==='') {
        SimpleToastWrapper({
          content: 'Name is required.',
          type: 'error',
          alignItem: 'left',
        });
        document.getElementById('name1').focus();

        return false;
      } else if (document.getElementById('address1').value.trim()==='') {
        SimpleToastWrapper({
          content: 'Address is required.',
          type: 'error',
          alignItem: 'left',
        });
        document.getElementById('address1').focus();

        return false;
      } else if (selCity===null || selCity.cityId===undefined) {
        SimpleToastWrapper({
          content: 'City is required.',
          type: 'error',
          alignItem: 'left',
        });
        document.getElementById('city').focus();

        return false;
      } else if (document.getElementById('website').value.trim()==='') {
        SimpleToastWrapper({
          content: 'Website is required.',
          type: 'error',
          alignItem: 'left',
        });
        document.getElementById('website').focus();

        return false;
      }

      var param = {
        type: '9-10',
        name1: document.getElementById('name1').value.trim(),
        address1: document.getElementById('address1').value.trim(),
        zipcode1: document.getElementById('zipcode1').value.trim(),
        country: selCity.countryId,
        city: selCity.cityId,
        telephone: document.getElementById('telephone').value.trim(),
        fax: document.getElementById('fax').value.trim(),
        email: document.getElementById('email').value.trim(),
        website: document.getElementById('website').value.trim(),
        generalInfo: document.getElementById('memo').value.trim(),
        isValid: true,
      };

      SupplierActions.createSupplier({supplier: param, navigate, successHandler: () => {navigate('/supplier/theme')}});
  }

return (
    <Page
        className={classes.root}
        title="Create theme supplier."
    >
        <Box
        display="flex"
        height="100%"
        justifyContent="center"
        >
        <Container maxWidth={false}>
        
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                        title="Create Theme Supplier"
                        // subheader="Register a new supplier"
                        />
                        <Divider />
                        <CardActions disableSpacing className={classes.buttonArea}>
                            <Button size="small" color="primary" variant="contained" type="submit" onClick={handleCreate}>
                                Create
                            </Button>
                        </CardActions>
                        <Divider />
                        <CardContent>
                            <Grid container spacing={3} >
                                {
                                    inputs.map(d => {
                                        if (d.t==='text') {
                                            return (
                                                <Grid item lg={3} md={6} xs={12} >
                                                    <TextField
                                                    fullWidth
                                                    label={d.l}
                                                    id={d.id}
                                                    name={d.id}
                                                    required={isNull(d.ir)}
                                                    defaultValue={isNullStr(d.dv)}
                                                    disabled={isNull(d.d)}
                                                    InputLabelProps={{ shrink: true, style: { textAlign: 'center' }}}
                                                    />
                                                </Grid>    
                                            )
                                        } else if (d.t==='city') {
                                            return (
                                                <React.Fragment>
                                                    {/* <Grid item lg={3} md={6} xs={12} >
                                                        <Autocomplete
                                                            id="country"
                                                            name="country"
                                                            fullWidth
                                                            style={{ marginTop: '3px' }}
                                                            getOptionLabel={(option) => option.label}
                                                            options={country}
                                                            loading={loadingCountry}
                                                            clearOnBlur={false}
                                                            filterOptions={filterOptions}
                                                            onInputChange={(event, value, reason) => handleInputCountryChange(value, reason)}
                                                            renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={"Input Country Name."}
                                                                size="small"
                                                                InputLabelProps={{ shrink: true, }}
                                                                InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                    <React.Fragment>
                                                                    {loadingCountry ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                    </React.Fragment>
                                                                ),
                                                                }}
                                                            />
                                                            )}
                                                        />
                                                    </Grid> */}
                                                    <Grid item lg={3} md={6} xs={12} >
                                                        <Autocomplete
                                                        id="city"
                                                        name="city"
                                                        fullWidth
                                                        style={{ marginTop: '3px' }}
                                                        groupBy={(option) => option.groupKey}
                                                        getOptionLabel={(option) => option.label}
                                                        options={city}
                                                        loading={loadingCity}
                                                        clearOnBlur={false}
                                                        filterOptions={filterOptions}
                                                        onInputChange={(event, value, reason) => handleInputCityChange(value, reason)}
                                                        onChange={(event, value, reason) => handleCitySelect(value, reason)}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            {...params}
                                                            label={"4. City"}
                                                            size="small"
                                                            InputLabelProps={{ shrink: true, }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment: (
                                                                <React.Fragment>
                                                                    {loadingCity ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                                ),
                                                            }}
                                                            />
                                                        )}
                                                        />
                                                </Grid>
                                            </React.Fragment>
                                            )
                                        } else if (d.t==='textArea') {
                                            return (
                                                
                                                <Grid item xs={12} >
                                                    <InputLabel htmlFor="component-simple" shrink>{d.l}</InputLabel>
                                                    <CommentInformation
                                                    placeHolder="Please input the memo about this supplier."
                                                    setCommentRef={setCommentRef}
                                                    />
                                            </Grid>
                                            )
                                        }
                                    })
                                }
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions disableSpacing className={classes.buttonArea}>
                            <Button size="small" color="primary" variant="contained" type="submit" onClick={handleCreate}>
                                Create
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
        </Box>
    </Page>
    );
};

export default connect(
    (state, ownProps) => ({
    }),
    (dispatch) => ({
      SupplierActions: bindActionCreators(SupplierActions, dispatch),
    })
  )(ThemeAdd);