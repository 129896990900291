import React from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import moment from 'moment';

import { DatePicker } from 'src/components/Common';

import { isNull } from "src/components/Common/Constants";

const DatePickerType = ({ index, field }) => {
  const onChangeDates = ({startDate, endDate}) =>{
    field.setDates({
      startDate: startDate,
      endDate: endDate,
    })};

  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <FormControl className="GroupInformPicker" fullWidth>
          <InputLabel htmlFor="component-simple" shrink>{field.l + (field.d===true ? '   (Can\'t change)':'')}</InputLabel>
          <DatePicker.RangeDatePickerWrapper
            className="form-control GroupInformPicker"
            onChange={onChangeDates}
            startDate={field.dates.startDate}
            endDate={field.dates.endDate}
            isOutsideRange={true}
            isDisable={field.d!==undefined?field.d:false}
          />
        </FormControl>
      </Grid>    
  );
}

export default DatePickerType;