import axios from 'axios';
import qs from 'qs';

import { SERVER_URL } from '../components/Common/Constants';

export function getFile({ groupNo, fileUrl }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/downloadFile?key=${fileUrl}`,{ method: 'get', withCredentials: true });
}


export function getTourGroup({ groupNo }) {
  return axios(`${SERVER_URL}/b2b/groups/getTourGroup/${groupNo}`,{ method: 'get', withCredentials: true });
}
export function getTourGroups({ groupCode, startDate, endDate }) {
  return axios(`${SERVER_URL}/b2b/groups/getTourGroups?startDate=${startDate}&endDate=${endDate}&groupCode=${groupCode}`,{ method: 'get', withCredentials: true });
}
export function getTemporarySavedTourGroups({ groupCode, startDate, endDate }) {
  return axios(`${SERVER_URL}/b2b/groups/getTemporarySavedTourGroups`,{ method: 'get', withCredentials: true });
}
export function getTemporarySavedTourGroup({ groupTempNo }) {
  return axios(`${SERVER_URL}/b2b/groups/getTemporarySavedTourGroup/${groupTempNo}`,{ method: 'get', withCredentials: true });
}
export function saveGroupTemporary({ formData }) {
  return axios(`${SERVER_URL}/b2b/groups/saveGroupTemporary`,{ method: 'post', data: formData, withCredentials: true });
}
export function deleteTemporarySavedTourGroup({ groupTempNo }) {
  return axios(`${SERVER_URL}/b2b/groups/deleteGroupTemporary/${groupTempNo}`,{ method: 'get', withCredentials: true });
}
export function updateGroup({ formData }) {
  return axios(`${SERVER_URL}/b2b/groups/updateGroup`,{ method: 'post', data: formData, withCredentials: true });
}
export function createGroup({ formData }) {
  return axios(`${SERVER_URL}/b2b/groups/createGroup`,{ method: 'post', data: formData, withCredentials: true });
}
export function changeGroupState({ groupNo }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/changeState`,{ method: 'get', withCredentials: true });
}
export function changeFix({ groupNo }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/changeFix`,{ method: 'get', withCredentials: true });
}
export function changeCancel({ groupNo }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/changeCancel`,{ method: 'get', withCredentials: true });
}

export function getGroupComments({ groupNo }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/comments`,{ method: 'get', withCredentials: true });
}
export function getGroupComment({ groupNo, commentNo }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/comment/${commentNo}`,{ method: 'get', withCredentials: true });
}
export function createComment({ formData }) {
  return axios(`${SERVER_URL}/b2b/groups/createComment`,{ method: 'post', data: formData, withCredentials: true });
}

export function getGroupHotels({ groupCode, startDate, endDate }) {
  return axios(`${SERVER_URL}/b2b/groups/getGroupHotels?startDate=${startDate}&endDate=${endDate}&groupCode=${groupCode}`,{ method: 'get', withCredentials: true });
}
export function setGroupHotelRqForFixed({ groupNo, hotelListNo, rqFixed, accommodationDate, hotelName }) {
  return axios(`${SERVER_URL}/b2b/groups/${groupNo}/hotels/rqForFixed/${hotelListNo}?accommodationDate=${accommodationDate}&hotelName=${hotelName}&rqFixed=${rqFixed}`);
}

export function getCountries({ countryName }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getCountries?countryName=${countryName}`,{ method: 'get', withCredentials: true });
}
export function getCities({ cityName, countryNo }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getCities?cityName=${cityName}&countryNo=${countryNo===undefined?'':countryNo}`,{ method: 'get', withCredentials: true });
}
export function getCitiesAndAirport({ cityName }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getCitiesAndAirports?cityName=${cityName}`,{ method: 'get', withCredentials: true });
}

export function getEntrances({ cities, entranceName }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getEntrances`,{ method: 'get',params:{
        cities: cities,
        entranceName: entranceName,
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    }, withCredentials: true });
}

export function getTechnicalVisits({ cities, technicalVisitName }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getTechnicalVisits`,{ method: 'get',params:{
        cities: cities,
        technicalVisitName: technicalVisitName,
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    }, withCredentials: true });
}

export function getHotels({ city, hotelName }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getHotels`,{ method: 'get',params:{
        cityId: city.key,
        hotelName: hotelName,
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    }, withCredentials: true });
}

export function getAirports({ name }) {
  return axios(`${SERVER_URL}/b2b/groups/codes/getAirports?name=${name}`,{ method: 'get', withCredentials: true });
}