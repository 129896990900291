import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Badge,
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import Page from 'src/components/Page';
import Toolbar from 'src/components/Supplier/Attraction/Toolbar';
import ExpandTableWrapper from 'src/components/Common/Grid/ExpandTableWrapper';

import * as SupplierActions from 'src/reducers/Supplier/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AttractionList = (props) => {
  const classes = useStyles();

  const [attractions, setAttractions] = useState([]);
  const navigate = useNavigate();
  
  const { SupplierActions } = props;

  const handleSearch = () => {
    const name = document.getElementById('name').value.toLowerCase();
    const country = document.getElementById('country').value;
    const city = document.getElementById('city').value.split(",")[0];
    const type = document.getElementById('type').value;

    SupplierActions.getAttractions({name, country, city, type, navigate, successHandler: (data) => {
      setAttractions(data.attractions);
    }});
  };

  const headCells = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'category', label: 'Category', width: '160px', align: 'right' },
    { id: 'countryName', label: 'Country', width: '180px', align: 'right' },
    { id: 'cityName', label: 'City/Location', width: '180px', align: 'right' },
    { id: 'contents', label: 'Contents', width: '60px' },
    { id: 'website', label: 'Website', width: '60px' },
  ];
  const bodyCells = [
    { key: 'name', align: 'left' },
    { key: 'category', align: 'right' },
    { key: 'countryName', align: 'right' },
    { key: 'cityName', align: 'right', children: (({ data })=>{
      return (
        (data.place!==null && data.cityName!==data.place.trim() && data.place.trim()!=='') && (<span><br/>( {data.place} )</span>)
      )
    }) },
    { key: 'contents', isOnlyChildren: true, children: (({ data })=>{
      return (
        data.contentsNum != 0 && (
          <Tooltip title="View contents">
          <Badge badgeContent={data.newHistory} color="error">
            <IconButton onClick={() => {window.open(`/popup/supplier/contents/${data.supplierNo}`, `Contents of ${data.name}`, `width=750, height=1200,location=no,status=no,scrollbars=yes`)}} aria-label="Contents open" size="small">
              <AllInboxIcon fontSize="inherit" />
            </IconButton>
          </Badge>
        </Tooltip>
        )
    )}) },
    { key: 'website', isOnlyChildren: true, children: (({ data })=>{
      return (
        data.website != null && (
          <Tooltip title="Website">
            <IconButton onClick={() => {window.open(data.website)}} aria-label="Website" size="small">
              <OpenInBrowserIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )
    )}) },
  ];

  return (
    <Page
      className={classes.root}
      title="Attraction list."
    >
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth={false}>
          <Toolbar 
            onClickSearch={handleSearch}
          />
          <ExpandTableWrapper 
            title="Attraction list"
            datas={attractions}
            headCells={headCells}
            bodyCells={bodyCells}
            contentFormat={(data) => {
              return (
              <div>
                {
                  (data.generalInfo!==null && data.generalInfo!==undefined && data.generalInfo.trim()!=='') && (
                    <React.Fragment>
                      <h2>[General Information]</h2>
                      <div dangerouslySetInnerHTML={ {__html: data.generalInfo} }  style={{padding: '5px 15px'}}/>
                    </React.Fragment>
                  )
                }
              </div>
            )}}
            isNumbering={true}
          />
        </Container>
      </Box>
    </Page>
  );
};

export default connect(
  (state, ownProps) => ({
  }),
  (dispatch) => ({
    SupplierActions: bindActionCreators(SupplierActions, dispatch),
  })
)(AttractionList);
