import axios from 'axios';
import qs from 'qs';

import { SERVER_URL } from '../components/Common/Constants';

export function getAttractions({ name, country, city, type }) {
  return axios(`${SERVER_URL}/b2b/supplier/getAttractions?name=${name}&country=${country}&city=${city}&type=${type}`,{ method: 'get', withCredentials: true });
}
export function getTechnicalVisits({ name, country, city, cate1, cate2 }) {
  return axios(`${SERVER_URL}/b2b/supplier/getTechnicalVisits?name=${name}&country=${country}&city=${city}&cate1=${cate1}&cate2=${cate2}`,{ method: 'get', withCredentials: true });
}
export function getThemes({ name, country, city, cate1, cate2, cate3 }) {
  return axios(`${SERVER_URL}/b2b/supplier/getThemes?name=${name}&country=${country}&city=${city}&cate1=${cate1}&cate2=${cate2}&cate3=${cate3}`,{ method: 'get', withCredentials: true });
}
export function getContents({ supplierNo }) {
  return axios(`${SERVER_URL}/b2b/supplier/getContents?supplierNo=${supplierNo}`,{ method: 'get', withCredentials: true });
}
export function createSupplier({ supplier }) {
  return axios(`${SERVER_URL}/b2b/supplier/createSupplier`,{ method: 'post', data: supplier, withCredentials: true });
}