import React from 'react';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';

import { Editor } from '@toast-ui/react-editor';

const SmartEditor = ({ ClassName, placeHolder, initialValue, initialHeight, setCommentRef, handleFocus }) => {
  const ref = React.createRef();

  React.useEffect(() => { 
      setCommentRef(ref.current.getInstance())
    }, []);

  return (
    <Editor
      placeholder={placeHolder}
      initialValue={initialValue}
      height={ initialHeight===undefined ? "400px" : initialHeight }
      initialEditType="wysiwyg"
      ref={ref}
      toolbarItems={[
        'heading',
        'bold',
        'italic',
        'strike',
        'divider',
        'hr',
        'quote',
        'divider',
        'ul',
        'ol',
        'task',
        'indent',
        'outdent',
      ]}
    />
  );
}

export default SmartEditor;