import axios from 'axios';
import qs from 'qs';

import { SERVER_URL } from '../components/Common/Constants';

export function loginApi(user) {
  return axios.post(
    `${SERVER_URL}/user/login`, user, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, config: { withCredentials: true } },
  );
}
export function logoutApi() {
  return axios(`${SERVER_URL}/user/logout`,{ method: 'get', withCredentials: true });
}

export function loginCheckApi() {
  return axios(`${SERVER_URL}/user/loginCheck`,{method: 'get',withCredentials: true,},
  );
}
export const loginCheck = async () => {
  try {
    const loginResult = await loginCheckApi();
    console.log(loginResult);
    return true;
  } catch (error) {
    return false;
  }
};
export const loginCheckWithRedirect = async (redirectFlag, history, targetUrl) => {
  const loginResult = await loginCheck();

  if (loginResult === redirectFlag) {
    history.push(targetUrl);
  }

  return loginResult;
};

export const convertCurrency = price => Number(price).toLocaleString('de-DE', { minimumFractionDigits: 2 });

export function getCodes({ groupId }) {
  return axios(`${SERVER_URL}/common/codes/${groupId}`,{ method: 'get', withCredentials: true });
}
export function getMultipleCodes({ groupIds }) {

  return axios(`${SERVER_URL}/common/codes/multiple`,{ method: 'get',params:{
        cdMstNos: groupIds,
    },
    paramsSerializer: function(params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    }, withCredentials: true });
}

export function getOperators() {
  return axios(`${SERVER_URL}/common/user/operators`,{ method: 'get', withCredentials: true });
}

export function getNotices({ startDate, endDate }) {
  return axios(`${SERVER_URL}/common/getNotices?startDate=${startDate}&endDate=${endDate}`,{ method: 'get', withCredentials: true });
}