import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon } from 'react-feather';

import { debounce } from 'lodash';

import * as CommonServices from 'src/services/CommonServices';
import * as GroupServices from 'src/services/GroupServices';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textField: {
    height: '38px'
  },
  button: {
    float: 'right',
    height: '38px'
  }
}));

const Toolbar = ({ className, onClickSearch, handle, ...rest }) => {
  const classes = useStyles();
  const [loadingCountry, setLoadingCountry] = React.useState(false);
  const [loadingCity, setLoadingCity] = React.useState(false);
  const [cate1, setCate1] = React.useState([]);
  const [cate2, setCate2] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [city, setCity] = React.useState([]);

  React.useEffect(() => { 
      fetchCate1();
      fetchCate2();
    }, []);

  const fetchCate1 = async () => {
    const datas = await CommonServices.getCodes({ groupId: 49 });
    setCate1(datas.data.cdMstTb.cdDtlTbs);
  };
  const fetchCate2 = async () => {
    const datas = await CommonServices.getCodes({ groupId: 50 });
    setCate2(datas.data.cdMstTb.cdDtlTbs);
  };
  
  const handleInputCountryChange = (value, reason) => {
    if (value.length >= 2 && reason === 'input') {
      debounceGetCoutryListFunc(value);
    }
  }
  const handleInputCityChange = (value, reason) => {
    if (value.length >= 2 && reason === 'input') {
      debounceGetCityListFunc(value);
    }
  }

  const debounceGetCoutryListFunc = debounce(async (value) => {
    setLoadingCountry(true);
    const lcValue = value.toLowerCase();

    const datas = await GroupServices.getCountries({countryName: lcValue});
    let result = datas.data.cities;

    setCountry(result);
    setLoadingCountry(false);
  }, 500);
  const debounceGetCityListFunc = debounce(async (value) => {
    setLoadingCity(true);
    const lcValue = value.toLowerCase();

    const datas = await GroupServices.getCities({cityName: lcValue});
    let result = datas.data.cities;

    setCity(result);
    setLoadingCity(false);
  }, 500);

  const filterOptions = (options, { inputValue }) => {return options;};

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
        <Card style={{overflow: 'visible'}}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid container item md={10} xs={12} style={{display:'contents'}} spacing={3}>
                <Grid item md xs={12}>
                  <TextField
                    label="Technical visit keyword"
                    id="name"
                    name="name"
                    fullWidth
                    InputLabelProps={{ shrink: true, }}
                  />
                </Grid>
                <Grid item md xs={12}>
                  <Autocomplete
                    id="country"
                    name="country"
                    fullWidth
                    style={{ marginTop: '3px' }}
                    getOptionLabel={(option) => option.label}
                    options={country}
                    loading={loadingCountry}
                    clearOnBlur={false}
                    filterOptions={filterOptions}
                    onInputChange={(event, value, reason) => handleInputCountryChange(value, reason)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Input Country Name."}
                        size="small"
                        InputLabelProps={{ shrink: true, }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCountry ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md xs={12}>
                  <Autocomplete
                    id="city"
                    name="city"
                    fullWidth
                    style={{ marginTop: '3px' }}
                    groupBy={(option) => option.groupKey}
                    getOptionLabel={(option) => option.label}
                    options={city}
                    loading={loadingCity}
                    clearOnBlur={false}
                    filterOptions={filterOptions}
                    onInputChange={(event, value, reason) => handleInputCityChange(value, reason)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Input City Name."}
                        size="small"
                        InputLabelProps={{ shrink: true, }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingCity ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md xs={12}>
                  <TextField
                    label="Category 1"
                    id="cate1"
                    name="cate1"
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true, }}
                  >
                    <option
                      value=""
                    >
                    == All ==
                    </option>
                  {
                    ( cate1 !== undefined && 
                      cate1.map((option,index) => (
                        <option
                          key={option['cdDtlNo']}
                          value={option['cdDtlCd']}
                        >
                        {option['name']}
                        </option>
                      ))
                    )
                  }
                  </TextField>
                </Grid>
                <Grid item md xs={12}>
                  <TextField
                    label="Category 2"
                    id="cate2"
                    name="cate2"
                    fullWidth
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true, }}
                  >
                    <option
                      value=""
                    >
                    == All ==
                    </option>
                  {
                    ( cate2 !== undefined && 
                      cate2.map((option,index) => (
                        <option
                          key={option['cdDtlNo']}
                          value={option['cdDtlCd']}
                        >
                        {option['name']}
                        </option>
                      ))
                    )
                  }
                  </TextField>
                </Grid>
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  endIcon={<SearchIcon />}
                  onClick={onClickSearch}
                  style={{ top:'10px' }}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
