import React from 'react';
import {
  ButtonGroup,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
  Paper,

  makeStyles,
} from '@material-ui/core';
import {
  Autocomplete,

  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineSeparator,
  TimelineItem,
} from '@material-ui/lab';
import HotelIcon from '@material-ui/icons/Hotel';
import PinDropIcon from '@material-ui/icons/PinDrop';
import AddIcon from '@material-ui/icons/Add';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import { debounce } from 'lodash';

import * as GroupServices from 'src/services/GroupServices';

import './ItineraryItemCities.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    margin: 0,
    marginBottom: '15px',
    boxShadow: '0 0 0 1px rgba(163,63,68,0.35), 0 1px 2px 0 rgba(163,63,68,0.25)',
  },
  cardHeader: {
    width: '100%',
    paddingBottom: '5px',
  },
  cardContent: {
    width: '100%',
    paddingRight: '0px',
    paddingLeft: '0px',
    paddingTop: '5px',
    paddingBottom: '5px',
      "&:last-child": {
        paddingBottom: '5px',
      }
  },
  paper: {
    padding: '6px 16px',
  },
  paperAutocomplete: {
    padding: '6px 6px',
  },
  addIcon: {
    backgroundColor: '#5f2135',
  },
  connector: {
    backgroundColor: '#3f51b5',
  },
  cityButton: {
    width: '24px',
    minWidth: '24px',
  }
}));

const ItineraryItemCities = ({ datas, isStart, isEnd, startLocation, endLocation }) => {
  const classes = useStyles();

  return (
      <Card className={classes.root}>
        <CardHeader 
          title="Route"
          className={classes.cardHeader}
        />
        <CardContent className={classes.cardContent}>
          <Timeline className="cityRouteTimeline">
          {
            (startLocation!==undefined && startLocation!==null) && (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    {(isStart) ? (<FlightLandIcon />) : (<HotelIcon />)}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">{startLocation.label}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          }
          {datas.map((data, index) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary" >
                  <PinDropIcon />
                </TimelineDot>
                <TimelineConnector className={classes.connector} />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1" style={{paddingTop:'5px'}}>{data.label}</Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
          {
            (endLocation!==null && isEnd===true) && (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <FlightTakeoffIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">{endLocation.label}</Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            )
          }
          </Timeline>
        </CardContent>
      </Card>    
  );
}

export default ItineraryItemCities;