import * as Actions from './actions';

export function isLoading(state = 0, action) {
  switch (action.type) {
    case Actions.FETCH_LOADING_REQUEST:
      return state + 1;
    case Actions.FETCH_LOADING_FINISH:
      return (state === 0) ? state : (state - 1);
    case Actions.FETCH_LOADING_RESET:
      return 0;
    default:
      return state;
  }
};