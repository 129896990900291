import React from 'react';
import {
  Grid,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from '@material-ui/core';

import { isNull, isNullStr } from "src/components/Common/Constants";

const TimePickerType = ({ index, field }) => {
  const onChangeHour = (event) => field.m(event.target.checked);

  return (
      <Grid 
        item
        lg={isNull(field.lg)}
        md={isNull(field.md)}
        xs={isNull(field.xs)}
      >
        <FormControl className="GroupInformPicker" fullWidth>
          <InputLabel htmlFor="component-simple" shrink>{field.l}</InputLabel>
          <div style={{marginTop:'16px', lineHeight:'32px', textAlign:'right'}}>
            <TextField
              select
              id={field.id+ "_hour"}
              name={field.id+ "_hour"}
              SelectProps={{ native: true }}
              style={{width:'45%'}}
            >
            {
              [...Array(24)].map(
                (data, index) => {
                  return (
                    <option key={index} value={index<10?"0"+index:index} >
                      {index<10?"0"+index:index}
                    </option>
                  )
                }
              )
            }
            </TextField>
            <div style={{width:'10%',display:'inline-block',textAlign:'center',fontWeight:'800'}}>:</div>
            <TextField
              select
              id={field.id+ "_minute"}
              name={field.id+ "_minute"}
              SelectProps={{ native: true }}
              style={{width:'45%'}}
            >
            {
              [...Array(12)].map(
                (data, index) => {
                  return (
                    <option key={index} value={(index*5)<10?"0"+(index*5):(index*5)} >
                      {(index*5)<10?"0"+(index*5):(index*5)}
                    </option>
                  )
                }
              )
            }
            </TextField>
          </div >
        </FormControl>
      </Grid>    
  );
}

export default TimePickerType;